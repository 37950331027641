import { Button, Grid, Link, Typography } from '@mui/material';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Fields } from '@pv/common/components';
import { useTheme } from '@material-ui/styles';
import { useAuthErrorMessage } from '@pv/common/hooks';
import { useSearchParams } from 'react-router-dom';

import { apiHost } from '@pv/common/api';
import { GlobalStyles } from '@mui/styled-engine';
import { Box } from '@mui/material';
import { useUserAgent } from '@pv/core/src/hooks/useUserAgent';
const API_HOST = apiHost(process.env.REACT_APP_PV_ENV);

const Footer = () => {
  const theme = useTheme();
  const { isWeb } = useUserAgent();
  return (
    <div
      style={{
        marginTop: 48,
      }}
    >
      <Typography align="left" variant="body2">
        Forgot your password?{' '}
        <Link
          href="/users/password/new"
          style={{
            textDecoration: 'none',
          }}
        >
          <Typography
            component="span"
            variant="subtitle2"
            style={{
              color: theme.palette.secondary.main,
            }}
          >
            Reset
          </Typography>
        </Link>
      </Typography>
      {isWeb && (
        <Typography style={{ marginTop: 20 }} align="left" variant="body2">
          Don't have an account?{' '}
          <Link
            href="/users/sign_up"
            style={{
              textDecoration: 'none',
            }}
          >
            <Typography
              component="span"
              variant="subtitle2"
              sx={{
                color: theme.palette.secondary.main,
              }}
            >
              Sign up now
            </Typography>
          </Link>
        </Typography>
      )}
    </div>
  );
};

export default function LoginPage({ email: initialEmail = null }) {
  const theme = useTheme();
  const [email, setEmail] = useState(initialEmail || '');
  const [password, setPassword] = useState('');
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [searchParams, _] = useSearchParams();

  useAuthErrorMessage((auth) => {
    if (auth.params?.email) {
      setEmail(auth.params.email);
    }
  });

  return (
    <>
      <GlobalStyles
        styles={{
          html: { height: '100%' },
          body: { backgroundColor: theme.palette.white, height: '100%' },
        }}
      />
      <Helmet>
        <title>Sign In | Perfect Venue</title>
      </Helmet>
      <Box
        sx={{
          height: '100vh',
          width: '100vw',
          overflowY: { xs: 'scroll', md: 'hidden' },
          overflowX: 'hidden',
          display: 'flex',
        }}
        data-cy="sign-in-page"
      >
        <Box
          sx={{
            height: '100%',
            width: { xs: '100%', md: '50%' },
            paddingX: { xs: 2, md: 4 },
            margin: 0,
            overflowY: 'scroll',
          }}
          data-cy="sign-in-page-form"
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'left',
            }}
          >
            <Box
              sx={{
                paddingTop: { xs: 2, md: 4 },
              }}
            >
              <img
                src="/pv-logo-name.png"
                alt="Perfect Venue Logo"
                style={{
                  height: 40,
                  width: 'auto',
                }}
              />
            </Box>
          </Box>
          <Box
            sx={{
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'start',
              marginTop: { xs: 4, s: 6, md: 8, lg: 10 },
            }}
          >
            <Box
              sx={{
                width: { xs: 300, md: 400 },
              }}
            >
              <img
                src="/sign-in.svg"
                alt="Let's get this party started"
                style={{
                  width: '100%',
                }}
              />
              <div
                style={{
                  marginTop: 40,
                }}
              >
                <form
                  onSubmit={() => setSubmitDisabled(true)}
                  id="sign-in-form"
                  method="POST"
                  action={`${API_HOST}/users/sign_in?${searchParams}`}
                >
                  <Typography
                    style={{ marginBottom: 20 }}
                    align="left"
                    variant="h6"
                  >
                    Sign in
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Fields.PvTextField
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        name="user[email]"
                        label="Email"
                        inputProps={{ autoComplete: 'email' }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Fields.PvPasswordField
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        name="user[password]"
                        label="Password"
                        inputProps={{ autoComplete: 'current-password' }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Button
                        disabled={submitDisabled}
                        fullWidth
                        type="submit"
                        variant="contained"
                        size="large"
                        color="secondary"
                      >
                        Continue
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              </div>
              <div>
                <Footer />
              </div>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            width: '50%',
            height: '100%',
            backgroundImage: `url('/crowd.jpg')`,
            backgroundSize: 'cover',
            backgroundAttachment: 'local',
            backgroundRepeat: 'no-repeat',
            display: { xs: 'none', md: 'block' },
          }}
          data-cy="sign-in-page-background"
        ></Box>
      </Box>
    </>
  );
}
