import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { Fields } from '@pv/common/components';
import { updateStartOffset, updateEndOffset, updateStartDate } from './utils';
import { useEvent } from '../../providers/event';
import { Divider } from '@material-ui/core';
import { HalfRowContainer, RowContainer, SectionContainer } from './components';

const RehearsalInfo = ({ onSave }) => {
  const { event } = useEvent();
  const canUpdateEvent = event?.canUpdate?.value;
  const { rehearsalStartDate, rehearsalStartOffset, rehearsalEndOffset } =
    event;

  const [startDate, setStartDate] = useState(rehearsalStartDate);
  const [startOffset, setStartOffset] = useState(rehearsalStartOffset);
  const [endOffset, setEndOffset] = useState(rehearsalEndOffset);

  const updateLocalValues = ({ startDate, startOffset, endOffset }) => {
    setStartDate(startDate);
    setStartOffset(startOffset);
    setEndOffset(endOffset);
  };

  const updateDateTimeRange = ({ updatedValue }) => {
    const updatedRange = {
      startDate,
      endDate: startDate,
      startOffset,
      endOffset,
      ...updatedValue, // overwriting with updated value
    };

    updateLocalValues(updatedRange);

    updatedRange.endDate = updatedRange.startDate;
    onSave({
      rehearsalDateTimeRange: updatedRange,
    });
  };

  useEffect(() => {
    updateLocalValues({
      startDate: rehearsalStartDate,
      startOffset: rehearsalStartOffset,
      endOffset: rehearsalEndOffset,
    });
  }, [rehearsalStartDate, rehearsalStartOffset, rehearsalEndOffset]);

  return (
    <SectionContainer>
      <Divider />
      <Box>
        <Fields.PvDateField
          testId="rehearsal-date-input"
          fullWidth
          variant="outlined"
          id="rehearsalDate"
          label="Rehearsal Date"
          value={startDate}
          onChange={(d) => updateStartDate(d, updateDateTimeRange)}
          disabled={!canUpdateEvent}
        />
      </Box>
      <RowContainer>
        <HalfRowContainer>
          <Fields.PvTimeField
            data-cy="rehearsal-start-input"
            variant="outlined"
            id="rehearsalStartTime"
            label="Start Time"
            value={startOffset || ''}
            onChange={(e) =>
              updateStartOffset(e.target.value, updateDateTimeRange)
            }
            disabled={!canUpdateEvent}
          />
        </HalfRowContainer>
        <HalfRowContainer>
          <Fields.PvTimeField
            data-cy="rehearsal-end-input"
            variant="outlined"
            id="rehearsalEndTime"
            label="End Time"
            value={endOffset || ''}
            onChange={(e) =>
              updateEndOffset(e.target.value, updateDateTimeRange)
            }
            disabled={!canUpdateEvent}
          />
        </HalfRowContainer>
      </RowContainer>
    </SectionContainer>
  );
};

export default RehearsalInfo;
