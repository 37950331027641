import { FrigadeAnnouncement, FrigadeTour, useFlows } from '@frigade/react';
import { FLOW_IDS } from './config';
import React from 'react';
import { gql, useQuery } from '@apollo/client';
import { useVenues } from '../../providers/venues';
import { useMutation } from '@pv/common/hooks';
import { contactSalesMutation } from '../PricingPanel';
import { enqueueSnackbar } from 'notistack';
import { useUserAuth } from '../../providers/auth';

const GET_SAMPLE_EVENT = gql`
  query SampleEvent($limit: Int, $venueIds: [ID!]!) {
    activeEvents(first: $limit, venueIds: $venueIds) {
      edges {
        node {
          id
        }
      }
    }
  }
`;

export function OnboardingComponents() {
  const { venueIds } = useVenues();
  const { user } = useUserAuth();
  const { getStepStatus } = useFlows();
  const organization = user?.organization;
  const [sampleEventId, setSampleEventId] = React.useState<string>('');
  const hasCompletedFirstStepOfTour =
    getStepStatus(FLOW_IDS.NEW_USER_TOUR, 'dashboard-leads') ===
    'COMPLETED_STEP';
  const mouseClickEvents = ['mousedown', 'click', 'mouseup'];

  const [contactSales] = useMutation(contactSalesMutation, {
    onNoErrorsCompleted: () => {
      enqueueSnackbar(
        'Thanks for your interest. Our sales team will reach out shortly.',
        { variant: 'success' }
      );
    },
  });

  const onClickContactSales = () => {
    if (organization) {
      const input = { organizationId: organization.id };
      const variables = { input };
      contactSales({ variables });
    } else {
      window.open('https://www.perfectvenue.com/perfect-venue-video-demo');
    }
  };

  const simulateMouseClick = (element: any) => {
    mouseClickEvents.forEach((mouseEventType) =>
      element.dispatchEvent(
        new MouseEvent(mouseEventType, {
          view: window,
          bubbles: true,
          cancelable: true,
          buttons: 1,
        })
      )
    );
  };

  const { data } = useQuery(GET_SAMPLE_EVENT, {
    variables: {
      venueIds,
      limit: 1,
    },
    onCompleted: (data) => {
      const eventId = data?.activeEvents?.edges?.[0]?.node?.id;

      if (eventId) {
        setSampleEventId(eventId);
      }
    },
    skip: !venueIds || hasCompletedFirstStepOfTour || sampleEventId !== '',
  });

  return (
    <>
      <style>
        {`
.fr-smallSubtitle {
    letter-spacing: 0px;
}
`}
      </style>
      {(sampleEventId || hasCompletedFirstStepOfTour) && (
        <FrigadeTour
          flowId={FLOW_IDS.NEW_USER_TOUR}
          customVariables={{
            sampleEventId: sampleEventId,
          }}
          showStepCount={false}
          onButtonClick={(step, index, cta) => {
            if (step.id === 'preview-screen' && cta == 'secondary') {
              onClickContactSales();
            }
            if (step.id === 'proposal-add-item') {
              const element = document.querySelector(
                '.frigade-tooltip-proposal-add-item'
              );
              simulateMouseClick(element);
            }
            if (step.id === 'preview-button') {
              const element = document.querySelector(
                '.frigade-tooltip-preview-button'
              );
              simulateMouseClick(element);
            }

            return true;
          }}
          dismissible
          appearance={{
            styleOverrides: {
              tooltipContainer: {
                width: '400px',
                maxWidth: '400px',
                borderRadius: '20px',
                boxShadow:
                  '0px 0px 3px 0px rgba(0, 0, 0, 0.12), 0px 10px 20px 0px rgba(0, 0, 0, 0.03), 0px 6px 12px 0px rgba(0, 0, 0, 0.09)',
              },
              smallTitle: {
                fontSize: '20px',
              },
              smallSubtitle: {
                fontSize: '16px',
              },
              button: {
                fontSize: '14px',
                borderRadius: '6px',
              },
              buttonSecondary: {
                fontSize: '14px',
                borderRadius: '6px',
              },
              tooltipCTAContainer: {
                justifyContent: 'flex-end',
              },
            },
          }}
        />
      )}
      <FrigadeTour
        flowId={FLOW_IDS.NOTES_TOOLTIP}
        showStepCount={false}
        dismissible
        appearance={{
          styleOverrides: {
            tooltipContainer: {
              width: '400px',
              maxWidth: '400px',
              borderRadius: '20px',
              boxShadow:
                '0px 0px 3px 0px rgba(0, 0, 0, 0.12), 0px 10px 20px 0px rgba(0, 0, 0, 0.03), 0px 6px 12px 0px rgba(0, 0, 0, 0.09)',
            },
            smallTitle: {
              fontSize: '20px',
            },
            smallSubtitle: {
              fontSize: '16px',
            },
            button: {
              fontSize: '14px',
              borderRadius: '6px',
            },
            buttonSecondary: {
              fontSize: '14px',
              borderRadius: '6px',
            },
            tooltipCTAContainer: {
              justifyContent: 'flex-end',
            },
          },
        }}
      />
      <FrigadeAnnouncement
        flowId={FLOW_IDS.NEW_USER_ANNOUNCEMENT}
        appearance={{
          styleOverrides: {
            modalContainer: {
              maxWidth: '820px',
              backgroundColor: '#F7F0ED',
              padding: '40px',
            },
            announcementContainer: {
              maxWidth: '100%',
              backgroundColor: 'transparent',
            },
            announcementTitle: {
              fontSize: '36px',
              fontStyle: 'normal',
              fontWeight: '700',
              lineHeight: '115%',
              marginTop: 0,
            },
            announcementSubtitle: {
              fontSize: '20px',
              fontStyle: 'normal',
              fontWeight: '400',
              lineHeight: '150%',
              marginTop: 0,
            },
            announcementMediaContainer: {
              height: '394px',
              display: 'flex',
              justifyContent: 'center',
            },
            announcementButton: {
              maxWidth: '217px',
              height: '52px',
              backgroundColor: '#FE6257',
              fontSize: '20px',
              justifyContent: 'center',
              alignItems: 'center',
            },
            modalClose: {
              color: '#808080',
            },
            wistiaPlayer: {
              maxWidth: '720px',
            },
          },
        }}
        modalPosition="center"
      />
    </>
  );
}
