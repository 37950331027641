import { Button, Grid, Typography, Box } from '@mui/material';
import React, { useState } from 'react';
import { Fields } from '@pv/common/components';
import FlashSnackbars from '../../components/FlashSnackbars';
import { DevisePage } from '../../components/DevisePage';
import { useAuthErrorMessage } from '@pv/common/hooks';
import { apiHost } from '@pv/common/api';
const API_HOST = apiHost(process.env.REACT_APP_PV_ENV);

const NewPasswordPage = () => {
  const [email, setEmail] = useState('');
  const [submitDisabled, setSubmitDisabled] = useState(false);

  useAuthErrorMessage();

  return (
    <DevisePage title="Forgot Password" maxPaperWidth={390}>
      <FlashSnackbars>
        <form
          onSubmit={() => setSubmitDisabled(true)}
          method="POST"
          action={`${API_HOST}/users/password`}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              paddingTop: '20px',
              paddingBottom: '10px',
            }}
          >
            <Box>
              <img
                style={{
                  width: 31,
                  height: 'auto',
                }}
                src="/forgot.png"
                alt="lock with question mark"
              ></img>
            </Box>
          </Box>
          <Typography style={{ marginBottom: 20 }} align="center" variant="h5">
            Forgot Your Password?
          </Typography>
          <Typography
            style={{ marginBottom: 40 }}
            align="center"
            variant="body1"
          >
            We understand, it happens. Just enter your email and we'll send you
            a link to reset.
          </Typography>
          <Grid
            container
            spacing={2}
            style={{
              paddingLeft: 20,
              paddingRight: 20,
              paddingBottom: 10,
            }}
          >
            <Grid item xs={12}>
              <Fields.PvTextField
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                name="user[email]"
                label="Email"
              />
            </Grid>
            <Grid
              item
              xs={12}
              style={{
                marginTop: 12,
              }}
            >
              <Button
                disabled={submitDisabled}
                fullWidth
                type="submit"
                variant="contained"
                color="secondary"
              >
                Continue
              </Button>
            </Grid>
          </Grid>
        </form>
      </FlashSnackbars>
    </DevisePage>
  );
};

export default NewPasswordPage;
