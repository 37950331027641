import React, { createContext, useContext } from 'react';
import storage from 'local-storage-fallback';
import { useFlows } from '@frigade/react';
import { FLOW_IDS } from '@pv/core/src/components/Frigade/config';
import { useUserAuth } from '@pv/core/src/providers/auth';

const OnboardingContext = createContext({
  showQuickStart: false,
});

export const useOnboarding = () => useContext(OnboardingContext);

export const OnboardingProvider = ({ children }: { children: JSX.Element }) => {
  const { isFlowAvailableToUser, getFlowStatus } = useFlows();
  const { user } = useUserAuth();

  const isFirstUser = user?.organizationMembership?.initialMember;

  const isFlowDone = getFlowStatus(FLOW_IDS.QUICKSTART_GUIDE);

  const isAvailable = isFlowAvailableToUser(FLOW_IDS.QUICKSTART_GUIDE);
  const showQuickStart = React.useMemo(() => {
    const condition =
      !storage.getItem('quickstart') &&
      isAvailable &&
      isFirstUser &&
      isFlowDone !== 'COMPLETED_FLOW';

    return !!condition;
  }, [isAvailable, isFirstUser, isFlowDone]);

  return (
    <OnboardingContext.Provider value={{ showQuickStart: showQuickStart }}>
      {children}
    </OnboardingContext.Provider>
  );
};
