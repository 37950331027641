import React from 'react';
import { Box, Link, Stack, Typography } from '@mui/material';
import moment from 'moment';
import { PvDialog } from '@pv/common/components';
import { usePublicConfig } from '@pv/common/providers';

interface Event {
  reserveLastUpdatedAt?: Date;
  reserveErrorMessage?: string;
  reserveReservationId?: string;
  groupSize?: number;
  startDate?: Date;
  startOffset?: number;
  endOffset?: number;
  planner: {
    firstName?: string;
    lastName?: string;
    email?: string;
    phone?: string;
  };
}

function validateReserveReservation(event: Event) {
  const validationErrors = [];

  if (!event.planner.firstName) {
    validationErrors.push('Contact first name');
  }

  if (!event.planner.lastName) {
    validationErrors.push('Contact last name');
  }

  if (!event.planner.email) {
    validationErrors.push('Contact email');
  }

  if (!event.planner.phone) {
    validationErrors.push('Contact phone number');
  }

  if (!event.groupSize || event.groupSize < 1 || event.groupSize > 999) {
    validationErrors.push('Group size must be between 1-999');
  }

  if (!event.startDate) {
    validationErrors.push('Start date');
  }

  if (!event.startOffset) {
    validationErrors.push('Start time');
  }

  if (!event.endOffset) {
    validationErrors.push('End time');
  }

  return validationErrors;
}

export function ReserveInfo({
  event,
  open,
  onClose,
}: {
  event: Event;
  open: boolean;
  onClose: () => void;
}) {
  const { publicConfig } = usePublicConfig();
  const validationErrors = validateReserveReservation(event);
  const invalid = validationErrors.length > 0;

  return (
    <PvDialog
      hideConfirmButton
      open={open}
      onClose={() => {
        onClose();
      }}
      title="Reserve by SpotOn"
      onConfirm={() => {}}
    >
      <Stack gap="16px">
        {event.reserveReservationId && publicConfig.reserve?.appHost && (
          <Link
            href={
              publicConfig.reserve.appHost +
              '/reservations/edit/' +
              event.reserveReservationId
            }
            target="_blank"
            rel="noreferrer"
          >
            View Reservation in Reserve
          </Link>
        )}
        <Box>
          {event.reserveLastUpdatedAt
            ? `Last synced at ${moment(event.reserveLastUpdatedAt).format(
                'lll'
              )}`
            : 'Not synced'}
        </Box>

        {invalid && (
          <Box
            sx={{
              borderRadius: '8px',
              backgroundColor: 'rgba(255, 235, 163, 0.5)',
              padding: '14px',
            }}
          >
            <Typography>
              Missing required information:
              <ul>
                {validationErrors.map((e, i) => {
                  return <li key={i}>{e}</li>;
                })}
              </ul>
            </Typography>
          </Box>
        )}
        {event.reserveErrorMessage && (
          <Box
            sx={{
              borderRadius: '8px',
              backgroundColor: 'rgba(254, 98, 87, 0.5)',
              padding: '14px',
            }}
          >
            <Typography>Reserve Error: {event.reserveErrorMessage}</Typography>
          </Box>
        )}
      </Stack>
    </PvDialog>
  );
}
