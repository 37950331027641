import { gql, useQuery } from '@apollo/client';
import {
  List,
  ListItem,
  ListItemText,
  Paper,
  Popper,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import moment from 'moment';
import React from 'react';

import { useVenues } from '../providers/venues';
import {
  EventSearchResultsQuery,
  EventSearchResultsQueryVariables,
} from '@pv/common/graphql/operations';

type Event = EventSearchResultsQuery['multisearch']['events'][0];

const useStyles = makeStyles((theme) => ({
  search: {
    backgroundColor: 'rgba(255,255,255, 0.1)',
    borderRadius: 4,
    flexBasis: 240,
    height: 36,
    padding: theme.spacing(0, 2),
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
  },
  searchIcon: {
    marginRight: theme.spacing(2),
    color: 'inherit',
  },
  popper: {
    zIndex: theme.zIndex.modal + 100,
  },
  popperContent: {
    width: '100%',
    overflowY: 'hidden',
  },
  searchInputWrapper: {
    padding: theme.spacing(2),
  },
  searchResults: {
    maxHeight: 400,
    overflowY: 'scroll',
  },
}));

const multisearchQuery = gql`
  query EventSearchResults($venueIds: [ID!]!, $query: String!) {
    multisearch(venueIds: $venueIds, query: $query) {
      id
      query
      events {
        id
        name
        startDate
        startOffset
        endDate
        endOffset
        planner {
          id
          fullName
        }
      }
    }
  }
`;

interface Props {
  query: string;
  searchRef: React.MutableRefObject<HTMLDivElement>;
  eventSelected: boolean;
  onSelectEvent: (event: Event) => void;
}

export const EventSearchResults = ({
  query,
  searchRef,
  eventSelected,
  onSelectEvent,
}: Props) => {
  const { venues, venueIds } = useVenues();
  const classes = useStyles();
  const variables: EventSearchResultsQueryVariables = { venueIds, query };
  const skip = !venues || !query;
  const { data } = useQuery<EventSearchResultsQuery>(multisearchQuery, {
    variables,
    skip,
  });
  const results = data?.multisearch?.events || [];

  const open = !eventSelected && !!query;
  return (
    <Popper
      anchorEl={searchRef.current}
      className={classes.popper}
      open={open}
      transition
    >
      <Paper className={classes.popperContent} elevation={3}>
        <div className={classes.searchResults}>
          <List>
            {isEmpty(results) ? (
              <ListItem>
                <ListItemText>
                  <Typography>No Results</Typography>
                </ListItemText>
              </ListItem>
            ) : (
              map(results, (event) => (
                <ListItem
                  className="event-search-result"
                  button
                  key={event.id}
                  onClick={() => onSelectEvent(event)}
                >
                  <ListItemText>
                    <div>{event.planner?.fullName}</div>
                    <Typography color="textSecondary">
                      {event.name} (
                      {moment(event.startDate)
                        .startOf('day')
                        .add(event.startOffset, 'minutes')
                        .format('MMM D, YYYY')}
                      )
                    </Typography>
                  </ListItemText>
                </ListItem>
              ))
            )}
          </List>
        </div>
      </Paper>
    </Popper>
  );
};
