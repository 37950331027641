import { useQuery } from '@apollo/client';
import storage from 'local-storage-fallback';
import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';
import includes from 'lodash/includes';
import filter from 'lodash/filter';
import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  useMemo,
  useCallback,
} from 'react';
import { useNavigate } from 'react-router-dom';
import {
  AuthenticatedUserQuery,
  ProviderVenuesQuery,
} from '@pv/common/graphql/operations';

import { venuesQuery } from './graphql';
import { useUserAuth } from '../providers/auth';
import { PickDeep, Unpacked } from '@pv/common/utils';

type VenueMembership = NonNullable<
  Unpacked<PickDeep<AuthenticatedUserQuery, 'venueMemberships'>>
>;

const VenuesContext = createContext<{
  venues: ProviderVenuesQuery['venues'] | null | undefined;
  venueIds: string[];
  setCurrentVenueMemberships: (
    vms: VenueMembership[],
    redirect?: boolean
  ) => void;
  loading: boolean;
  refetchVenues: () => void;
}>({
  venues: null,
  venueIds: [],
  loading: false,
  setCurrentVenueMemberships: () => {},
  refetchVenues: () => {},
});

export const useVenues = () => useContext(VenuesContext);

const cvmKey = 'vmids';

export const storeCurrentVenueMemberships = (
  venueMemberships: VenueMembership[]
) => {
  storage.setItem(cvmKey, JSON.stringify(map(venueMemberships, (vm) => vm.id)));
};

const VenuesProvider = ({
  children,
}: {
  children: JSX.Element[] | JSX.Element;
}) => {
  const navigate = useNavigate();
  const { user } = useUserAuth();
  const [venueMemberships, setVenueMemberships] = useState<VenueMembership[]>();

  const setCurrentVenueMembershipsAndStore = useCallback(
    (vms: VenueMembership[]) => {
      storeCurrentVenueMemberships(vms);
      setVenueMemberships(vms);
    },
    [setVenueMemberships]
  );

  const setCurrentVenueMemberships = useCallback(
    (vms: VenueMembership[], redirect = false) => {
      if (isEmpty(vms)) {
        return;
      }
      setCurrentVenueMembershipsAndStore(vms);
      if (redirect) {
        navigate('/events');
      }
    },
    [navigate, setCurrentVenueMembershipsAndStore]
  );

  useEffect(() => {
    if (user && isEmpty(user.activeVenueMemberships)) {
      return;
    } else if (user && !venueMemberships) {
      let currentVenueMembershipIds = storage.getItem(cvmKey);
      if (currentVenueMembershipIds) {
        try {
          currentVenueMembershipIds = JSON.parse(currentVenueMembershipIds);
        } catch (e) {
          // pass
        }
      }
      let vms = filter(user.activeVenueMemberships, (vm) =>
        includes(currentVenueMembershipIds, vm.id)
      );
      if (isEmpty(vms)) {
        vms = [user.activeVenueMemberships[0]];
      }
      setCurrentVenueMembershipsAndStore(vms);
    }
  }, [user, venueMemberships, setCurrentVenueMembershipsAndStore]);

  const venueIds = map(venueMemberships, (vm) => vm.venue.id);

  const variables = { ids: venueIds };
  const skip = isEmpty(venueIds);
  const { data, refetch, loading } = useQuery<ProviderVenuesQuery>(
    venuesQuery,
    {
      variables,
      skip,
    }
  );
  const venues = data?.venues;

  const memoizedValue = useMemo(
    () => ({
      venues,
      venueIds,
      loading,
      setCurrentVenueMemberships,
      refetchVenues: refetch,
    }),
    [venues, venueIds, setCurrentVenueMemberships, refetch]
  );

  return (
    <VenuesContext.Provider value={memoizedValue}>
      {children}
    </VenuesContext.Provider>
  );
};

export default VenuesProvider;
