import React from 'react';
import HamburgerMenu from './HamburgerMenu';
import LoggedInElements from './LoggedInElements';

const SmallScreenItems = ({
  setSearchOpen,
}: {
  setSearchOpen: (open: boolean) => void;
}) => {
  return (
    <>
      <HamburgerMenu />
      <LoggedInElements setSearchOpen={setSearchOpen} />
    </>
  );
};

export default SmallScreenItems;
