import React from 'react';
import { Box, Checkbox, Typography } from '@mui/material';

export const InboxHeader = ({
  setAllSelected,
  selected,
  premiumFeaturesEnabled,
}: {
  setAllSelected: (s: boolean) => void;
  selected: boolean;
  premiumFeaturesEnabled: boolean;
}) => (
  <Box
    sx={{
      padding: '8px 20px 8px 14px',
      borderBottom: '1px solid rgba(0, 0, 0, 0.08)',
    }}
  >
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: '28px',
        width: '100%',
      }}
    >
      <Box>
        <Checkbox
          color="primary"
          checked={selected}
          onChange={(event) => {
            setAllSelected(event.target.checked);
          }}
        />
      </Box>
      <Box sx={{ flexBasis: '32px', flexGrow: 0, flexShrink: 0 }} />
      <Box sx={{ flexBasis: '225px', flexGrow: 0, flexShrink: 0 }}>
        <Typography variant="tableHeader">Name</Typography>
      </Box>
      <Box sx={{ flexBasis: '100px', flexGrow: 0, flexShrink: 0 }}>
        <Typography variant="tableHeader">Event Date</Typography>
      </Box>
      <Box
        sx={{
          flexGrow: 0,
          flexShrink: 0,
          overflow: 'hidden',
        }}
      >
        <Typography variant="tableHeader">Message</Typography>
      </Box>
      <Box sx={{ textAlign: 'right', flexGrow: 1 }}>
        <Typography variant="tableHeader">Last Emailed</Typography>
      </Box>
      {premiumFeaturesEnabled && (
        <Box>
          <Typography variant="tableHeader">Owner</Typography>
        </Box>
      )}
    </Box>
  </Box>
);
