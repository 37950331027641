import React, { useCallback, useMemo } from 'react';
import {
  Box,
  Button,
  Typography,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import {
  useReadAllEventMessages,
  useUnreadLatestEventMessages,
  useReadAllVenueEventMessages,
  useUnreadLatestVenueEventMessages,
} from '@pv/common/graphql';
import { useUserAuth } from '../../providers/auth';
import { useVenues } from '../../providers/venues';
import { useNavigate } from 'react-router-dom';

export const InboxToolbar = ({
  eventCount = 0,
  unreadEventCount = 0,
  selected,
  allSelected,
  ownerIds,
  setOwnerIds,
  showUnreadOnly,
  setShowUnreadOnly,
  premiumFeaturesEnabled,
}: {
  eventCount?: number;
  unreadEventCount?: number;
  selected: Record<number, boolean>;
  allSelected: boolean;
  ownerIds?: string[];
  setOwnerIds: (ownerIds: string[] | undefined) => void;
  showUnreadOnly: boolean;
  setShowUnreadOnly: (val: boolean) => void;
  premiumFeaturesEnabled: boolean;
}) => {
  const theme = useTheme();
  const largeScreen = useMediaQuery(theme.breakpoints.up('lg'));
  const { venueIds } = useVenues();
  const navigate = useNavigate();
  const [readAllEventMessages] = useReadAllEventMessages({});
  const [unreadLatestEventMessages] = useUnreadLatestEventMessages({});
  const [readAllVenueEventMessages] = useReadAllVenueEventMessages({});
  const [unreadLatestVenueEventMessages] = useUnreadLatestVenueEventMessages(
    {}
  );
  const { user } = useUserAuth();

  const markAsUnread = useCallback(() => {
    if (allSelected) {
      unreadLatestVenueEventMessages({
        variables: { input: { venueIds, exclude: Object.keys(selected) } },
      });
    } else {
      unreadLatestEventMessages({
        variables: { input: { ids: Object.keys(selected) } },
      });
    }
    navigate(0);
  }, [
    selected,
    unreadLatestEventMessages,
    allSelected,
    unreadLatestVenueEventMessages,
    venueIds,
    navigate,
  ]);

  const markAsRead = useCallback(() => {
    if (allSelected) {
      readAllVenueEventMessages({
        variables: { input: { venueIds, exclude: Object.keys(selected) } },
      });
    } else {
      readAllEventMessages({
        variables: { input: { ids: Object.keys(selected) } },
      });
    }
    navigate(0);
  }, [
    selected,
    readAllEventMessages,
    allSelected,
    readAllVenueEventMessages,
    venueIds,
    navigate,
  ]);

  const totalSelected = useMemo(() => {
    if (!allSelected) {
      return Object.keys(selected).length;
    } else {
      return eventCount - Object.keys(selected).length;
    }
  }, [allSelected, eventCount, selected]);

  const eventCopy = eventCount === 1 ? 'event' : 'events';

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          padding: '20px 24px',
          borderBottom: '1px solid rgba(0, 0, 0, 0.08)',
          height: largeScreen ? '74px' : 'auto',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: largeScreen ? 'center' : 'left',
            gap: largeScreen ? '18px' : 0,
            flexDirection: largeScreen ? 'row' : 'column',
          }}
        >
          <Typography variant="h5">Inbox</Typography>
          <Typography variant="body1" color="text.secondary">
            {unreadEventCount} unread, {eventCount} {eventCopy}
          </Typography>
        </Box>
        {largeScreen && totalSelected > 0 && (
          <Box sx={{ display: 'flex', gap: '18px', alignItems: 'center' }}>
            <Typography variant="body1">
              {totalSelected} {eventCopy} selected
            </Typography>
            <Button
              disabled={totalSelected === 0}
              variant="outlined"
              color="error"
              onClick={markAsUnread}
            >
              Mark as Unread
            </Button>
            <Button
              disabled={totalSelected === 0}
              variant="outlined"
              color="error"
              onClick={markAsRead}
            >
              Mark as Read
            </Button>
          </Box>
        )}
        {totalSelected === 0 && user && (
          <Box
            sx={{
              display: 'flex',
              gap: largeScreen ? '18px' : '12px',
              alignItems: largeScreen ? 'center' : 'start',
            }}
          >
            {premiumFeaturesEnabled && (
              <Button
                variant="outlined"
                color="error"
                size={largeScreen ? 'medium' : 'small'}
                onClick={() => {
                  ownerIds ? setOwnerIds(undefined) : setOwnerIds([user.id]);
                }}
              >
                {ownerIds ? 'All Events' : 'My Events'}
              </Button>
            )}
            <Button
              variant="outlined"
              color="error"
              size={largeScreen ? 'medium' : 'small'}
              onClick={() => setShowUnreadOnly(!showUnreadOnly)}
            >
              {showUnreadOnly ? 'Show Read' : 'Hide Read'}
            </Button>
          </Box>
        )}
      </Box>
    </>
  );
};
