import { gql } from '@apollo/client';

export const inboxEventsQuery = gql`
  query InboxEvents(
    $venueIds: [ID!]!
    $cursor: String
    $ownerIds: [ID!]
    $unread: Boolean
  ) {
    inboxEvents(
      venueIds: $venueIds
      after: $cursor
      ownerIds: $ownerIds
      unread: $unread
    ) {
      totalCount
      unreadCount
      pageInfo {
        startCursor
        endCursor
      }
      edges {
        cursor
        node {
          id
          name
          startDate
          endDate
          startOffset
          endOffset
          lastEmailedAt
          latestMessageBatched {
            sanitizedTrimmedHtml
            sentAt
          }
          planner {
            firstName
            lastName
            fullName
          }
          unreadMessageCount
          status
          user {
            image
            firstName
            lastName
            fullName
          }
        }
      }
    }
  }
`;
