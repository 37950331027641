import React, { useEffect, useState } from 'react';
import { enqueueSnackbar } from 'notistack';
import map from 'lodash/map';
import some from 'lodash/some';
import { useSearchParams } from 'react-router-dom';

const variantFromFlash = (kind) => {
  if (kind === 'alert') {
    return 'error';
  } else if (kind === 'success') {
    return 'success';
  }
  return 'default';
};

export const FlashSnackbars = ({ children }) => {
  const [flash, setFlash] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const elements = document.getElementsByClassName('pv-hidden-flash-alert');
    if (elements) {
      const flash = map(elements, (e) => ({
        text: e.textContent,
        variant: e.dataset.variant,
      }));
      setFlash(flash);
    }
  }, []);

  useEffect(() => {
    if (searchParams.has('flash_message')) {
      const flash = [
        {
          text: searchParams.get('flash_message'),
          variant: searchParams.get('flash_variant'),
        },
      ];

      searchParams.delete('flash_message');
      searchParams.delete('flash_variant');
      setFlash(flash);
      setSearchParams(searchParams);
    }
  }, [searchParams, setSearchParams]);

  useEffect(() => {
    if (some(flash)) {
      const flashDup = [...flash];
      const flashMessage = flashDup.pop();
      enqueueSnackbar(flashMessage.text, {
        variant: variantFromFlash(flashMessage.variant),
      });
      setFlash(flashDup);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flash]);

  return <>{children}</>;
};

export default FlashSnackbars;
