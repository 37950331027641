import React from 'react';
import { useLocation } from 'react-router-dom';
import FreeTrialAppBar from './FreeTrialAppBar';
import PrimaryAppBar from './PrimaryAppBar/PrimaryAppBar';
import SecondaryAppBar from './SecondaryAppBar';
import SubscriptionStatusBar from './SubscriptionStatusBar';
import { OnboardingComponents } from '../../Frigade/OnboardingComponents';
import { AppBar, Box } from '@mui/material';

const DesktopNavBar = () => {
  const location = useLocation();
  const { pathname } = location;

  const showSecondaryAppbar = pathname.match(/^\/reports/);

  return (
    <Box>
      <AppBar
        sx={{
          boxShadow: 'none',
          zIndex: 999,
        }}
      >
        <FreeTrialAppBar />
        <SubscriptionStatusBar />
        <PrimaryAppBar />
        {showSecondaryAppbar && <SecondaryAppBar />}
      </AppBar>
      <OnboardingComponents />
    </Box>
  );
};

export default DesktopNavBar;
