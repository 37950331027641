import React, { useRef, useState } from 'react';
import {
  Divider,
  Button,
  Typography,
  Menu,
  MenuItem,
  useTheme,
  useMediaQuery,
  Fade,
} from '@mui/material';
import size from 'lodash/size';
import first from 'lodash/first';
import map from 'lodash/map';
import some from 'lodash/some';
import { ChevronDown as ChevronDownIcon } from 'react-feather';

import { useUserAuth } from '../../../../../providers/auth';
import { useVenues } from '../../../../../providers/venues';

const VenueDropDown = () => {
  const accountRef = useRef(null);
  const theme = useTheme();
  const { user } = useUserAuth();
  const { venues, setCurrentVenueMemberships } = useVenues();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleOpenMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  let venueLabel;
  let selectedVenue;
  if (size(venues) === 1) {
    selectedVenue = first(venues);
    venueLabel = selectedVenue.internalName || selectedVenue.name;
  } else if (size(venues) > 1) {
    venueLabel = 'All Venues';
  } else {
    venueLabel = 'No active venues';
  }

  const mediumScreen = useMediaQuery(theme.breakpoints.between('md', 'lg'));
  const shouldTruncateVenueName = mediumScreen && size(venueLabel) > 15;
  const truncatedVenueNameLength = shouldTruncateVenueName ? '90px' : 'unset';
  const setCurrentVenueMembershipAndClose = (venueMembership) => {
    setCurrentVenueMemberships([venueMembership], true);
    handleCloseMenu();
  };

  const isDropDown = size(user.activeVenueMemberships) > 1;

  const onClickAll = () => {
    setCurrentVenueMemberships(user.activeVenueMemberships, true);
    handleCloseMenu();
  };

  if (!isDropDown) {
    return (
      <Fade in={!!venueLabel}>
        <div>
          <div
            className="venue-menu-button"
            style={{
              cursor: 'pointer',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              margin: `0px ${theme.spacing(3)}px`,
            }}
          >
            <Typography
              noWrap
              style={{
                margin: `0px ${theme.spacing(1)}px`,
                maxWidth: truncatedVenueNameLength,
              }}
              color="inherit"
              overflow="hidden"
              textOverflow="ellipsis"
            >
              {venueLabel}
            </Typography>
          </div>
        </div>
      </Fade>
    );
  }

  const alphabetizedVenueMemberships = [...user?.activeVenueMemberships]?.sort(
    (a, b) => {
      const nameA = a.venue.internalName || a.venue.name;
      const nameB = b.venue.internalName || b.venue.name;
      return nameA.localeCompare(nameB);
    }
  );

  return (
    <Fade in={some(venues)}>
      <div>
        <Button
          variant="text"
          className="venue-menu-button"
          id="venue-selector-button"
          onClick={handleOpenMenuClick}
          aria-label="select venue"
          aria-controls={open ? 'venue-selector-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          ref={accountRef}
          style={{
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            margin: `0px ${theme.spacing(1)}px`,
            minWidth: 100,
            color: 'white',
          }}
        >
          <Typography
            noWrap
            style={{
              margin: `0px ${theme.spacing(1)}px`,
              maxWidth: truncatedVenueNameLength,
            }}
            color="inherit"
            className="venue-button"
            overflow="hidden"
            textOverflow="ellipsis"
          >
            {venueLabel}
          </Typography>
          {venueLabel && <ChevronDownIcon />}
        </Button>
        <Menu
          id="venue-selector-menu"
          anchorEl={anchorEl}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          open={open}
          onClose={handleCloseMenu}
          MenuListProps={{
            'aria-labelledby': 'venue-selector-button',
          }}
        >
          <MenuItem onClick={onClickAll}>All Venues</MenuItem>
          <Divider />
          {map(alphabetizedVenueMemberships, (venueMembership) => (
            <MenuItem
              key={venueMembership.id}
              selected={
                selectedVenue && venueMembership.venue.id === selectedVenue.id
              }
              onClick={() => setCurrentVenueMembershipAndClose(venueMembership)}
            >
              {venueMembership.venue.internalName || venueMembership.venue.name}
            </MenuItem>
          ))}
        </Menu>
      </div>
    </Fade>
  );
};

export default VenueDropDown;
