import { Box, Divider } from '@mui/material';
import { PageLoading } from '@pv/common/components';
import { useMutation } from '@pv/common/hooks';
import React, { Fragment, useCallback, useEffect, useState } from 'react';
import {
  createAdditionalContactMutation,
  removeAdditionalContactMutation,
} from '../graphql';
import {
  ContactAreaEventType,
  ContactAreaPlannerType,
  SavePlannerMutationType,
} from './types';
import { ContactItem } from './components/ContactItem';
import {
  SectionContainer,
  ButtonRowContainer,
  SmallTextButton,
} from '../components';
export const ContactArea = ({
  event,
  updateEventImmediately,
  readOnly = false,
}: {
  event: ContactAreaEventType;
  updateEventImmediately: ({
    variables,
  }: SavePlannerMutationType) => Promise<void>;

  readOnly?: boolean;
}) => {
  const primaryContact = event.planner as ContactAreaPlannerType;

  const [showAdditional, setShowAdditional] = useState(false);
  const [additionalContacts, setAdditionalContacts] = useState<
    ContactAreaPlannerType[]
  >(event.additionalContacts);

  useEffect(() => {
    setAdditionalContacts(event.additionalContacts);
  }, [event]);

  const [addAdditionalContact] = useMutation(createAdditionalContactMutation, {
    onNoErrorsCompleted: ({ createAdditionalContact }: any) => {
      const event = createAdditionalContact.event;
      setAdditionalContacts(event.additionalContacts);
    },
  });

  const [removeAdditionalContact] = useMutation(
    removeAdditionalContactMutation,
    {
      onNoErrorsCompleted: ({ removeAdditionalContact }: any) => {
        const event = removeAdditionalContact.event;
        setAdditionalContacts(event.additionalContacts);
      },
    }
  );

  const resetAdditionalContacts = useCallback(() => {
    const savedContacts = additionalContacts.filter(
      (c: ContactAreaPlannerType) => !!c.id
    );
    setAdditionalContacts(savedContacts as any);
  }, [additionalContacts]);

  const onRemoveAdditionalContact = useCallback(
    (plannerId?: string) => {
      if (plannerId) {
        const input = { plannerId, eventId: event.id };
        removeAdditionalContact({ variables: { input } });
      } else {
        resetAdditionalContacts();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [event]
  );

  const makePrimary = (plannerId: string) => {
    const primary = additionalContacts.find((c) => c.id === plannerId);
    if (primary) {
      const previouslyPrimaryContactInput = {
        plannerId: event.planner?.id,
        eventId: event.id,
      };
      addAdditionalContact({
        variables: { input: previouslyPrimaryContactInput },
      });

      const newlyPrimaryContactInput = {
        id: event.id,
        plannerId: primary.id,
      };
      const variables = { input: newlyPrimaryContactInput };
      updateEventImmediately({ variables });
    }
  };

  if (!event) {
    return <PageLoading />;
  }

  const onClickAddContact = () => {
    setAdditionalContacts([...additionalContacts, {} as any]);
    setShowAdditional(true);
  };

  const handleRemovePrimaryContact = () => {
    if (additionalContacts.length > 0) {
      const previousPrimaryContact = event.planner;
      const primaryContact = additionalContacts[0];
      const newlyPrimaryContactInput = {
        id: event.id,
        plannerId: primaryContact.id,
      };
      const variables = { input: newlyPrimaryContactInput };
      updateEventImmediately({ variables });
      onRemoveAdditionalContact(previousPrimaryContact?.id);
    } else {
      onClickAddContact();
    }
  };

  if (!event) {
    return <PageLoading />;
  }

  const onToggleShowAll = () => {
    setShowAdditional(!showAdditional);
  };

  const saveAdditionalContact = ({
    variables,
  }: SavePlannerMutationType): any => {
    const { id: eventId, plannerId, planner } = variables.input;
    const input = { eventId, plannerId, ...(planner ? planner : {}) };
    addAdditionalContact({ variables: { input } });
  };

  const eventId = event.id;
  const additionalCount = additionalContacts.length;

  const getToggleShowText = () => {
    if (additionalCount > 0 && showAdditional) {
      return 'Hide';
    } else if (additionalCount > 0 && !showAdditional) {
      return `Show All (${additionalCount})`;
    } else {
      return '';
    }
  };

  const toggleShowText = getToggleShowText();

  return (
    <SectionContainer gap="8px">
      <ContactItem
        // key={primaryContact?.id}
        eventId={eventId}
        planner={primaryContact}
        resetAdditionalContacts={resetAdditionalContacts}
        onSave={updateEventImmediately}
        readOnly={readOnly}
        onUnlink={
          additionalContacts.length > 0 ? handleRemovePrimaryContact : undefined
        }
      />

      {showAdditional &&
        additionalContacts?.map((planner: ContactAreaPlannerType) => {
          return (
            <Fragment key={`additional-contact-${planner.id}`}>
              <Divider />
              <ContactItem
                planner={planner}
                eventId={eventId}
                resetAdditionalContacts={resetAdditionalContacts}
                onSave={saveAdditionalContact}
                onUnlink={
                  planner.id
                    ? () => onRemoveAdditionalContact(planner.id)
                    : undefined
                }
                readOnly={readOnly}
                makePrimary={makePrimary}
              />
            </Fragment>
          );
        })}
      <ButtonRowContainer>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            flexDirection: 'row',
            width: '100%',
          }}
        >
          {!readOnly && (
            <SmallTextButton onClick={onClickAddContact}>
              Add Contact
            </SmallTextButton>
          )}
          {toggleShowText && (
            <SmallTextButton onClick={onToggleShowAll}>
              {toggleShowText}
            </SmallTextButton>
          )}
        </Box>
      </ButtonRowContainer>
    </SectionContainer>
  );
};
