import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useQuery } from '@apollo/client';
import { PageLoading, Section } from '@pv/common/components';
import { InboxEventEdge } from '@pv/common/graphql/types';
import { Virtuoso } from 'react-virtuoso';
import { useMediaQuery, useTheme } from '@mui/material';
import { inboxEventsQuery } from '../queries';
import { useVenues } from '../../providers/venues';
import { InboxToolbar } from './InboxToolbar';
import { InboxHeader } from './InboxHeader';
import { InboxItem } from './InboxItem';
import { InboxItemMinimal } from './InboxItemMinimal';
import { InboxRow } from './InboxRow';
import { some } from 'lodash';

export const Inbox = ({ height }: { height: number }) => {
  const theme = useTheme();
  const largeScreen = useMediaQuery(theme.breakpoints.up('lg'));

  const { venueIds, venues } = useVenues();
  const premiumFeaturesEnabled = some(
    venues,
    (v) => v.featureScope?.premiumFeaturesEnabled
  );

  const [ownerIds, setOwnerIds] = useState<string[] | undefined>();
  const [showUnreadOnly, setShowUnreadOnly] = useState<boolean>(false);

  const { data, fetchMore, loading, refetch } = useQuery(inboxEventsQuery, {
    variables: { venueIds, ownerIds, unread: showUnreadOnly },
    skip: false,
  });

  useEffect(() => {
    refetch();
  }, [venueIds, refetch, ownerIds, showUnreadOnly]);

  const nodes = data?.inboxEvents?.edges.map(
    (edge: InboxEventEdge) => edge.node
  );

  const loadNextPage = useCallback(() => {
    fetchMore({
      variables: {
        cursor: data?.inboxEvents?.pageInfo.endCursor,
      },
    });
  }, [data?.inboxEvents?.pageInfo.endCursor, fetchMore]);

  const [selected, setSelected] = useState<Record<number, boolean>>({});
  const [allSelected, setAllSelected] = useState(false);

  const itemSelector = useCallback(
    (id: number) => {
      return (s: boolean) => {
        const result = Object.assign({}, selected);

        if (allSelected) {
          if (s) {
            delete result[id];
          } else {
            result[id] = true;
          }
        } else {
          if (!s) {
            delete result[id];
          } else {
            result[id] = true;
          }
        }

        setSelected(result);
      };
    },
    [allSelected, selected]
  );

  return (
    <Section>
      <InboxToolbar
        eventCount={data?.inboxEvents?.totalCount}
        unreadEventCount={data?.inboxEvents?.unreadCount}
        selected={selected}
        allSelected={allSelected}
        ownerIds={ownerIds}
        setOwnerIds={setOwnerIds}
        showUnreadOnly={showUnreadOnly}
        setShowUnreadOnly={setShowUnreadOnly}
        premiumFeaturesEnabled={premiumFeaturesEnabled}
      />
      {largeScreen && (
        <InboxHeader
          setAllSelected={(s) => {
            setSelected({});
            setAllSelected(s);
          }}
          selected={allSelected}
          premiumFeaturesEnabled={premiumFeaturesEnabled}
        />
      )}
      {!loading && data ? (
        <Virtuoso
          data-cy="inbox-rows"
          data={nodes}
          itemContent={(i, event) => (
            <InboxRow event={event} index={i}>
              {largeScreen ? (
                <InboxItem
                  event={event}
                  selected={
                    allSelected ? !selected[event.id] : !!selected[event.id]
                  }
                  setSelected={itemSelector(event.id)}
                  premiumFeaturesEnabled={premiumFeaturesEnabled}
                />
              ) : (
                <InboxItemMinimal event={event} />
              )}
            </InboxRow>
          )}
          style={{ height }}
          endReached={loadNextPage}
        />
      ) : (
        <PageLoading />
      )}
    </Section>
  );
};
