import { gql } from '@apollo/client';
import { contractFields, eventFields } from '../../providers/graphql';

export const updateEventMutation = gql`
  mutation UpdateEvent2($input: UpdateEventInput!) {
    updateEvent(input: $input) {
      event {
        id
        ...EventFields
        spaceAvailability
      }
      errors {
        message
      }
    }
  }
  ${eventFields}
`;

export const createAdditionalContactMutation = gql`
  mutation CreateAdditionalContact($input: CreateAdditionalContactInput!) {
    createAdditionalContact(input: $input) {
      event {
        id
        ...EventFields
        spaceAvailability
        contract {
          id
          ...ContractFields
        }
      }
      errors {
        message
      }
    }
  }
  ${eventFields}
  ${contractFields}
`;

export const removeAdditionalContactMutation = gql`
  mutation RemoveAdditionalContact($input: RemoveAdditionalContactInput!) {
    removeAdditionalContact(input: $input) {
      event {
        id
        ...EventFields
        spaceAvailability
        contract {
          id
          ...ContractFields
        }
      }
      errors {
        message
      }
    }
  }
  ${eventFields}
  ${contractFields}
`;

export const removeEventTourMutation = gql`
  mutation RemoveEventTour($input: RemoveEventTourInput!) {
    removeEventTour(input: $input) {
      event {
        id
        tourStartDate
        tourStartOffset
        tourEndOffset
      }
      errors {
        message
      }
    }
  }
`;

export const removeEventRehearsalMutation = gql`
  mutation RemoveEventRehearsal($input: RemoveEventRehearsalInput!) {
    removeEventRehearsal(input: $input) {
      event {
        id
        rehearsalStartDate
        rehearsalStartOffset
        rehearsalEndOffset
      }
      errors {
        message
      }
    }
  }
`;

export const duplicateEventMutation = gql`
  mutation DuplicateEvent($input: DuplicateEventInput!) {
    duplicateEvent(input: $input) {
      event {
        id
        ...EventFields
      }
      errors {
        message
      }
    }
  }
  ${eventFields}
`;
