import { FrigadeProvider, useOrganization, useUser } from '@frigade/react';

import React, { ReactElement, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUserAuth } from '../../providers/auth';
import { PRIMARY_COLOR, PUBLIC_KEY } from './config';
import { AuthenticatedUserQuery } from '@pv/common/graphql/operations';
import { useTheme, useMediaQuery } from '@mui/material';
type User = AuthenticatedUserQuery['authenticatedUser'];

function FrigadeProviderWrapper({ children }: { children: ReactElement }) {
  const navigate = useNavigate();
  const { user } = useUserAuth();
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  if (smallScreen) {
    return children;
  }

  if (process.env.REACT_APP_PV_ENV === 'test') return children;

  if (!user || !user.id) {
    return children;
  }

  return (
    <FrigadeProvider
      config={{
        navigate: (url, target): void => {
          if (target === '_blank') {
            window.open(url, '_blank');
          } else {
            navigate(url);
          }
        },
        defaultAppearance: {
          theme: {
            colorPrimary: PRIMARY_COLOR,
            colorTextSuccess: PRIMARY_COLOR,
            colorText: '#222222',
            borderRadius: 8,
          },
        },
      }}
      publicApiKey={PUBLIC_KEY}
      userId={user.id}
    >
      <FrigadeAuthInitializer user={user}>{children}</FrigadeAuthInitializer>
    </FrigadeProvider>
  );
}

function FrigadeAuthInitializer({
  user,
  children,
}: {
  user: User | null;
  children: ReactElement;
}) {
  const { addPropertiesToUser } = useUser();
  const { setOrganizationIdWithProperties } = useOrganization();

  useEffect(() => {
    if (user) {
      addPropertiesToUser({
        firstName: user.firstName ?? null,
        lastName: user.lastName ?? null,
        createdAt: user.createdAt,
        email: user.email,
        image: user.image ?? null,
      });
      if (user.organization) {
        setOrganizationIdWithProperties(user.organization.id, {
          name: user.organization.name ?? null,
          image: user.organization.image ?? null,
        });
      }
    }
  }, [user]);

  return children;
}

export default FrigadeProviderWrapper;
