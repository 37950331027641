import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { usePublicConfig } from '@pv/common/providers';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  img: {
    width: 'auto',
    height: '40px',
  },
}));

export default () => {
  const classes = useStyles();
  const { publicConfig } = usePublicConfig();

  return (
    <div className={classes.root}>
      <div>
        <img className={classes.img} src={publicConfig?.images?.pvLogoName} />
      </div>
    </div>
  );
};
