import { gql } from '@apollo/client';

export const eventFieldsFragment = gql`
  fragment CalendarEventFields on Event {
    id
    name
    startDate
    startOffset
    endDate
    endOffset
    tourStartDate
    tourStartOffset
    tourEndOffset
    rehearsalStartDate
    rehearsalStartOffset
    rehearsalEndOffset
    status
    groupSize
    spaces {
      id
      name
      displayColor
    }
    seniorSpace {
      id
      name
      displayColor
    }
    planner {
      id
      fullName
      phone
      email
    }
    user {
      id
      fullName
    }
    venue {
      id
      name
      internalName
    }
  }
`;

export const eventsQuery = gql`
  query Events($venueIds: [ID!]!, $dateTimeRange: DateTimeRangeInput) {
    events(venueIds: $venueIds, dateTimeRange: $dateTimeRange) {
      id
      ...CalendarEventFields
    }
  }
  ${eventFieldsFragment}
`;

export const availabilityBlockFields = gql`
  fragment AvailabilityBlockFields on AvailabilityBlock {
    name
    startDate
    startOffset
    endDate
    endOffset
    spaces {
      id
      name
    }
    venue {
      id
      internalName
      name
    }
  }
`;

export const createAvailabilityBlockMutation = gql`
  mutation CreateAvailabilityBlock($input: CreateAvailabilityBlockInput!) {
    createAvailabilityBlock(input: $input) {
      availabilityBlock {
        id
        ...AvailabilityBlockFields
      }
      errors {
        message
      }
    }
  }
  ${availabilityBlockFields}
`;

export const updateAvailabilityBlockMutation = gql`
  mutation UpdateAvailabilityBlock($input: UpdateAvailabilityBlockInput!) {
    updateAvailabilityBlock(input: $input) {
      availabilityBlock {
        id
        ...AvailabilityBlockFields
      }
      errors {
        message
      }
    }
  }
  ${availabilityBlockFields}
`;

export const removeAvailabilityBlockMutation = gql`
  mutation RemoveAvailabilityBlock($input: RemoveAvailabilityBlockInput!) {
    removeAvailabilityBlock(input: $input) {
      errors {
        message
      }
    }
  }
`;
