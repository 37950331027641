import React, { useMemo } from 'react';
import { InboxEvent } from '@pv/common/graphql/types';
import { Link as RouterLink } from 'react-router-dom';
import moment from 'moment';
import { Box, Checkbox, Typography, Link, Stack } from '@mui/material';
import { InboxItemAvatar } from './InboxItemAvatar';
import { InboxItemStatus } from './InboxItemStatus';
import { htmlToText, formatEventDate, formatEventTime } from '@pv/common/utils';

export const InboxItem = ({
  event,
  selected,
  setSelected,
  premiumFeaturesEnabled,
}: {
  event: InboxEvent;
  selected: boolean;
  setSelected: (selected: boolean) => void;
  premiumFeaturesEnabled: boolean;
}) => {
  const dateString = formatEventDate(event.startDate, event.endDate);

  const timeString = formatEventTime(event.startOffset, event.endOffset);

  const addSpaceForLineBreaks = (text: string) => {
    return text.replace(/(?:\r\n|\r|\n)/g, '  ');
  };

  const addSpaceAfterFirstComma = (text: string) => {
    return text.replace(/,/g, ', ');
  };

  const cleanMessage = useMemo(() => {
    const formatItemText = (text: string) => {
      text = htmlToText(text);
      text = addSpaceAfterFirstComma(text);
      text = addSpaceForLineBreaks(text);
      return text;
    };
    const latestMessage = ((event.latestMessageBatched as any) || [])[0];
    if (latestMessage?.sanitizedTrimmedHtml) {
      return formatItemText(latestMessage.sanitizedTrimmedHtml);
    }
  }, [event.latestMessageBatched]);

  const lastEmailed = useMemo(() => {
    if (event && event.lastEmailedAt) {
      const now = moment();
      const messageDate = moment(event.lastEmailedAt);
      if (!messageDate) {
        return 'Sending...';
      }
      let format;

      format = messageDate.isSame(now, 'day') ? 'h:mm A' : 'MMM D, h:mm A';

      return messageDate.format(format);
    }
  }, [event]);

  return (
    <>
      <InboxItemStatus height={'48px'} status={event.status} />

      <Box>
        <Checkbox
          onClick={(event) => {
            event.stopPropagation();
          }}
          color="primary"
          checked={selected}
          onChange={(event) => {
            setSelected(event.target.checked);
          }}
        />
      </Box>

      <Link
        to={`/events/${event.id}`}
        component={RouterLink}
        underline="none"
        sx={{
          display: 'flex',
          gap: '28px',
          alignItems: 'center',
          width: '100%',
          position: 'relative',
        }}
      >
        <Box sx={{ flexGrow: 0, flexShrink: 0 }}>
          {event.planner && (
            <InboxItemAvatar
              person={event.planner}
              unreadMessageCount={event.unreadMessageCount}
            />
          )}
        </Box>

        <Box
          sx={{
            flexBasis: '225px',
            flexGrow: 0,
            flexShrink: 0,
          }}
        >
          <Box>
            <Box
              sx={{
                WebkitLineClamp: 1,
                WebkitBoxOrient: 'vertical',
                display: '-webkit-box',
                overflow: 'hidden',
              }}
            >
              <Typography variant="subtitle1">{event.name}</Typography>
            </Box>
            <Typography variant="body2">{event.planner?.fullName}</Typography>
          </Box>
        </Box>

        <Box sx={{ flexBasis: '120px', flexGrow: 0, flexShrink: 0 }}>
          <Stack direction="column">
            <Typography variant="body1">{dateString}</Typography>
            <Typography variant="body2" color="textSecondary">
              {timeString}
            </Typography>
          </Stack>
        </Box>

        <Box sx={{ width: '650px', overflow: 'hidden' }}>
          <Typography noWrap={true} variant="body1" sx={{ hyphens: 'auto' }}>
            {cleanMessage}
          </Typography>
        </Box>

        <Box
          sx={{
            textAlign: 'right',
            flexGrow: 1,
          }}
        >
          <Typography variant="body2" color="text.secondary">
            {lastEmailed}
          </Typography>
        </Box>

        {premiumFeaturesEnabled && (
          <Box sx={{ minWidth: '40px' }}>
            {event.user && (
              <InboxItemAvatar person={event.user} bgcolor="primary.pink" />
            )}
          </Box>
        )}
      </Link>
    </>
  );
};
