import React from 'react';
import { Grid, Link, Paper, Typography, useTheme } from '@material-ui/core';
import { useNavigate } from 'react-router-dom';
import { lowerCase } from 'lodash';

export const LockedOut = ({ lockedOutVenues }) => {
  const theme = useTheme();
  const navigate = useNavigate();

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      spacing={4}
      direction="column"
    >
      <Grid item>
        <Paper
          elevation={2}
          style={{
            padding: theme.spacing(4),
            margin: theme.spacing(6),
            width: '50vw',
          }}
        >
          <Grid item>
            <Typography gutterBottom align="center" variant="h5">
              <strong>
                Your subscription is no longer active
                {lockedOutVenues ? ' for the following venues:' : '.'}
              </strong>
            </Typography>
          </Grid>
          <Grid item>
            {lockedOutVenues?.map((venue) => (
              <Typography gutterBottom align="center" variant="h5">
                {venue.name} <em>({lowerCase(venue.subscription.status)})</em>
              </Typography>
            ))}
          </Grid>
          <Grid item style={{ marginTop: theme.spacing(4) }}>
            <Typography gutterBottom align="center" variant="h5">
              <strong>
                Please update your payment method or subscription plan.
              </strong>
            </Typography>
          </Grid>
          <Grid item>
            <Typography align="center" variant="h6">
              <Link
                style={{ cursor: 'pointer' }}
                onClick={() => navigate(`/settings/organization`)}
              >
                Go to Subscription Settings
              </Link>
            </Typography>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};
