import moment from 'moment';
import isFinite from 'lodash/isFinite';
import parseInt from 'lodash/parseInt';

const formatDate = (value) => {
  return value && moment(value).format('YYYY-MM-DD');
};

export const getOffset = (value) => {
  return isFinite(parseInt(value)) ? parseInt(value) : null;
};

export const updateStartDate = (value, callback) => {
  const newStart = formatDate(value);
  const updatedValue = {
    startDate: newStart,
  };
  callback({ updatedValue });
};

export const updateEndDate = (value, callback) => {
  const newEnd = formatDate(value);
  const updatedValue = {
    endDate: newEnd,
  };
  callback({ updatedValue });
};

export const updateStartOffset = (value, callback) => {
  const newStartOffset = getOffset(value);
  const updatedValue = {
    startOffset: newStartOffset,
  };
  callback({ updatedValue });
};

export const updateEndOffset = (value, callback) => {
  const newEndOffset = getOffset(value);
  const updatedValue = {
    endOffset: newEndOffset,
  };
  callback({ updatedValue });
};
