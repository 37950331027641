import React from 'react';

const GreyDot = () => {
  return (
    <div
      style={{
        width: '4px',
        height: '4px',
        borderRadius: '50%',
        backgroundColor: 'rgba(192, 196, 210, 1)',
      }}
    ></div>
  );
};

export default GreyDot;
