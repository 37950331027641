import { gql, useQuery } from '@apollo/client';
import {
  Backdrop,
  ClickAwayListener,
  Divider,
  IconButton,
  InputAdornment,
  LinearProgress,
  Paper,
  Popover,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/styles';
import some from 'lodash/some';
import map from 'lodash/map';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Fields, PVAvatar } from '@pv/common/components';
import { Search, X } from 'react-feather';
import { PVHoverNavy } from '@pv/common/colors';

import { useVenues } from '../providers/venues';
import StatusChip from './StatusChip';

const useStyles = makeStyles((theme) => ({
  search: {
    backgroundColor: 'rgba(255,255,255, 0.1)',
    borderRadius: 8,
    flexBasis: 240,
    height: 36,
    padding: theme.spacing(0, 2),
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
  },
  searchIcon: {
    marginRight: theme.spacing(2),
    color: 'inherit',
  },
  popper: {
    zIndex: theme.zIndex.modal + 100,
  },
  popperContent: {
    width: '100%',
    overflowY: 'hidden',
    [theme.breakpoints.up('md')]: {
      width: 500,
    },
  },
  searchInputWrapper: {
    padding: theme.spacing(2),
  },
  searchResults: {
    maxHeight: 400,
    overflowY: 'scroll',
  },
  backdrop: {
    zIndex: theme.zIndex.modal - 1,
  },
}));

const multisearchQuery = gql`
  query Multisearch($venueIds: [ID!]!, $query: String!) {
    multisearch(venueIds: $venueIds, query: $query) {
      id
      query
      events {
        id
        name
        status
        startDate
        startOffset
        endDate
        endOffset
        planner {
          id
          fullName
          firstName
        }
      }
    }
  }
`;

const SearchResultItem = ({ event, onClick }) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <li
      className="event-search-result"
      button
      key={event.id}
      onClick={onClick}
      alignItems="flex-start"
      style={{
        padding: '20px',
        cursor: 'pointer',
        backgroundColor: isHovered ? PVHoverNavy : 'inherit',
      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <div>
            <PVAvatar firstName={event.planner.fullName} member={false} />
          </div>
          <div
            style={{
              marginLeft: 12,
            }}
          >
            <Typography variant="subtitle1">
              {event.planner.fullName}
            </Typography>
          </div>
        </div>
        <div>
          <StatusChip status={event.status} />
        </div>
      </div>
      <div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            padding: '12px 8px 8px 8px',
          }}
        >
          <Typography variant="body1">{event.name}</Typography>
        </div>
        {event.startDate && (
          <div
            style={{
              paddingLeft: '8px',
            }}
          >
            <Typography variant="body1" color="textSecondary">
              {moment(event.startDate)
                .startOf('day')
                .add(event.startOffset, 'minutes')
                .format('MMM D, YYYY')}
            </Typography>
          </div>
        )}
      </div>
    </li>
  );
};

const SearchResults = ({ open, onClose }) => {
  const { venues, venueIds } = useVenues();
  const classes = useStyles();
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();
  const [query, setQuery] = useState('');
  const [noMatches, setNoMatches] = useState(false);
  const variables = { venueIds, query };
  const skip = !venues || !query;
  const { data, loading } = useQuery(multisearchQuery, { variables, skip });
  const matchingDocs = useMemo(() => {
    const matchingEvents = data?.multisearch?.events || [];
    return [...matchingEvents];
  }, [data]);

  const onClickSearchAndClose = (event) => {
    onClose();
    navigate(`/events/${event.id}`);
  };

  useEffect(() => {
    if (!loading && query) {
      if (some(matchingDocs)) {
        setNoMatches(false);
      } else {
        setNoMatches(true);
      }
    }
  }, [loading, matchingDocs, query]);

  useEffect(() => {
    if (open) {
      setQuery('');
    }
  }, [open]);
  const left = window.innerWidth / 2;
  const top = smallScreen ? 0 : 200;
  return (
    <Backdrop open={open} className={classes.backdrop}>
      <Popover
        className={classes.popper}
        open={open}
        anchorReference="anchorPosition"
        anchorPosition={{ top, left }}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <ClickAwayListener onClickAway={onClose}>
          <Paper className={classes.popperContent} elevation={3}>
            <div className={classes.searchInputWrapper}>
              <Fields.PvTextField
                autoFocus
                inputProps={{
                  'aria-label': 'Search Events',
                }}
                placeholder="Search Events"
                value={query}
                onChange={(e) => setQuery(e.target.value)}
                disableUnderline
                variant="standard"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={onClose}>
                        <X />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            <Divider />
            <div style={{ height: 4 }}>
              {loading && <LinearProgress variant="query" />}
            </div>
            <div className={classes.searchResults}>
              <ul
                style={{
                  listStyle: 'none',
                  padding: 0,
                  margin: 0,
                }}
              >
                {noMatches && (
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Typography>No Matches</Typography>
                  </div>
                )}
                {map(matchingDocs, (event) => (
                  <SearchResultItem
                    key={event.id}
                    event={event}
                    onClick={() => onClickSearchAndClose(event)}
                  />
                ))}
              </ul>
            </div>
          </Paper>
        </ClickAwayListener>
      </Popover>
    </Backdrop>
  );
};

export default SearchResults;
