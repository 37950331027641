import React, { useRef, useState } from 'react';
import { useTheme, makeStyles } from '@material-ui/styles';
import {
  ClickAwayListener,
  IconButton,
  Paper,
  Popper,
} from '@material-ui/core';
import { HelpCircle } from 'react-feather';
import HelpList from './HelpList';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  popper: {
    zIndex: theme.zIndex.appBar + 100,
  },
  popperContent: {
    marginTop: theme.spacing(1),
  },
}));

const HelpMenu = () => {
  const theme = useTheme();
  const classes = useStyles();

  const helpRef = useRef(null);
  const [open, setOpen] = useState(false);

  const openHelpPopover = () => setOpen(true);
  const onHelpPopoverClose = () => setOpen(false);

  return (
    <>
      <IconButton
        title="Help Menu"
        aria-label="Help Menu"
        onClick={openHelpPopover}
        style={{ padding: '10px' }}
      >
        <span ref={helpRef} style={{ color: theme.palette.common.white }}>
          <HelpCircle />
        </span>
      </IconButton>
      <Popper
        anchorEl={helpRef.current}
        className={classes.popper}
        open={open}
        transition
      >
        <ClickAwayListener onClickAway={onHelpPopoverClose}>
          <Paper
            className={clsx('account-menu', classes.popperContent)}
            elevation={3}
          >
            <HelpList afterClick={onHelpPopoverClose} isMobile={false} />
          </Paper>
        </ClickAwayListener>
      </Popper>
    </>
  );
};

export default HelpMenu;
