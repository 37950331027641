export const activeEventStatuses = [
  'lead',
  'qualified',
  'proposal_sent',
  'confirmed',
  'balance_due',
];

export const EventStatus = {
  ACTIVE: 'Active Events',
  LEAD: 'Lead',
  QUALIFIED: 'Qualified',
  PROPOSAL_SENT: 'Proposal Sent',
  CONFIRMED: 'Confirmed',
  BALANCE_DUE: 'Balance Due',
  COMPLETED: 'Completed YTD',
};
