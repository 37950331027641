const FLOW_IDS_DEV = {
  ONBOARDING_FORM: 'flow_vypvAQpg2EXOvdQS',
  NEW_USER_TOUR: 'flow_fCw5vC2GAOvB3zJN',
  NEW_USER_ANNOUNCEMENT: 'flow_PODCBhNknNivXYcy',
  QUICKSTART_GUIDE: 'flow_gkP2DAdM9X8x4r27',
  NOTES_TOOLTIP: 'flow_3jh4oAx6b8kmWhg3',
};

const FLOW_IDS_PROD = {
  ONBOARDING_FORM: 'flow_9gA51GA1lnpz2Mx7',
  NEW_USER_TOUR: 'flow_fRGyXLrDcJDWGHoR',
  NEW_USER_ANNOUNCEMENT: 'flow_aXRQ2PDtveUvvIJq',
  QUICKSTART_GUIDE: 'flow_mehKDvwLJsRe4OPT',
  NOTES_TOOLTIP: 'flow_3jh4oAx6b8kmWhg3',
};

export const FLOW_IDS =
  process.env.NODE_ENV === 'production' ? FLOW_IDS_PROD : FLOW_IDS_DEV;

export const PUBLIC_KEY =
  process.env.NODE_ENV === 'production'
    ? 'api_public_JathGvO0g5ZIBtnsQ838zduIwVFp9lQaAGloNCAuk7oz4mbJ50WWWaE7NUSCqTLs'
    : 'api_public_WJFcmrHcgLQU6VDH7RT2deis2uAE20hV2k93JkUpU5nvQ2IRivXzuO0SDt0f4mbb';

export const PRIMARY_COLOR = '#FF5E4D';
