import React, { useState } from 'react';
import { Box, IconButton } from '@mui/material';
import join from 'lodash/join';
import compact from 'lodash/compact';
import { EditIcon, PVAvatar } from '@pv/common/components';
import ContactInfo from './ContactInfo';

export const ViewContact = ({
  planner,
  onClickEditContact,
  readOnly = false,
}) => {
  const [isHover, setIsHover] = useState(false);

  const avatarLength = 52;

  const {
    firstName = '',
    lastName = '',
    fullName = '',
    email = '',
    phone = '',
    account = {},
  } = planner;

  const accountName = account?.name;

  return (
    <Box
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      onFocus={() => setIsHover(true)}
      onBlur={() => setIsHover(false)}
      sx={{
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        alignItems: 'flex-start',
        justifyContent: 'left',
        gap: '16px',
      }}
    >
      <PVAvatar
        fontSize={24}
        height={avatarLength}
        member={false}
        firstName={fullName}
      />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'baseline',
          width: '100%',
        }}
      >
        <ContactInfo
          fullName={fullName}
          email={email}
          phone={phone}
          accountName={accountName}
        />

        {!readOnly && (
          <IconButton
            onClick={onClickEditContact}
            data-cy="edit-contact-button"
            size="small"
            sx={{
              opacity: isHover ? 1 : 0,
              color: '#222222',
              svg: {
                width: '20px',
                height: '20px',
              },
            }}
          >
            <EditIcon />
          </IconButton>
        )}
      </Box>
    </Box>
  );
};
