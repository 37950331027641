import React from 'react';
import { Chip } from '@mui/material';
import { Zap } from 'react-feather';
import { PVBlack, PVRed } from '@pv/common/colors';

export const ExpressBookChip = () => (
  <Chip
    label="Express Book"
    icon={<Zap color={PVRed} width={'20px'} height={'20px'} />}
    sx={{
      color: PVBlack,
      fontWeight: 'medium',
      fontSize: '13px',
      padding: '8px',
      background: 'linear-gradient(94deg, #FFEDE7 4.44%, #FFDEF0 95.56%)',
    }}
  />
);
