import { Button, Grid, Link, Typography, Box } from '@mui/material';
import React, { useState } from 'react';
import queryString from 'query-string';
import { usePublicConfig } from '@pv/common/providers';
import { Fields } from '@pv/common/components';
import FlashSnackbars from '../../components/FlashSnackbars';
import { DevisePage } from '../../components/DevisePage';
import { useAuthErrorMessage } from '@pv/common/hooks';
import { apiHost } from '@pv/common/api';
const API_HOST = apiHost(process.env.REACT_APP_PV_ENV);

const HiddenPasswordResetToken = () => {
  const params = queryString.parse(window.location.search);
  const resetPasswordToken = params.reset_password_token;
  return (
    <input
      type="hidden"
      name="user[reset_password_token]"
      value={resetPasswordToken}
    />
  );
};

const Footer = () => (
  <Box>
    <Box
      style={{
        marginTop: 30,
      }}
    >
      <Link
        href="/users/password/new"
        style={{
          textDecoration: 'none',
        }}
      >
        <Typography align="center" variant="body2">
          Forgot your password?
        </Typography>
      </Link>
    </Box>
    <Box>
      <Typography style={{ marginTop: 20 }} align="center" variant="body2">
        Don't have a Perfect Venue account?{' '}
        <Link
          href="https://perfectvenue.typeform.com/free-trial"
          style={{
            textDecoration: 'none',
          }}
        >
          <Typography component="span" variant="body2" color="secondary">
            Sign up now!
          </Typography>
        </Link>
      </Typography>
    </Box>
  </Box>
);

const EditPasswordPage = () => {
  const { publicConfig } = usePublicConfig();
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [submitDisabled, setSubmitDisabled] = useState(false);

  useAuthErrorMessage();

  return (
    <DevisePage title="Reset Password" footer={<Footer />}>
      <FlashSnackbars>
        <form
          onSubmit={() => setSubmitDisabled(true)}
          method="POST"
          action={`${API_HOST}${publicConfig?.paths?.userPassword}`}
        >
          <input type="hidden" name="_method" value="put" />
          <HiddenPasswordResetToken />
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              paddingTop: '20px',
              paddingBottom: '10px',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Box>
                <img
                  style={{
                    height: 60,
                    width: 'auto',
                  }}
                  src="/champagne.svg"
                  alt="champagne"
                />
              </Box>
              <Box
                style={{
                  marginTop: 4,
                }}
              >
                <Typography variant="h6" color="secondary">
                  Welcome!
                </Typography>
              </Box>
            </Box>
          </Box>
          <Typography style={{ marginBottom: 40 }} align="center" variant="h4">
            Choose a password
          </Typography>
          <Grid
            container
            spacing={2}
            style={{
              paddingLeft: 20,
              paddingRight: 20,
              paddingBottom: 10,
            }}
          >
            <Grid item xs={12}>
              <Fields.PvPasswordField
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                name="user[password]"
                label="Password"
              />
            </Grid>
            <Grid item xs={12}>
              <Fields.PvPasswordField
                value={passwordConfirmation}
                onChange={(e) => setPasswordConfirmation(e.target.value)}
                name="user[password_confirmation]"
                label="Password Confirmation"
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                disabled={submitDisabled}
                fullWidth
                type="submit"
                variant="contained"
                color="secondary"
              >
                Continue
              </Button>
            </Grid>
          </Grid>
        </form>
      </FlashSnackbars>
    </DevisePage>
  );
};

export default EditPasswordPage;
