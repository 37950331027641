import { Toolbar, Button, Typography } from '@mui/material';
import { useTheme } from '@material-ui/styles';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useVenues } from '../../../providers/venues';
import some from 'lodash/some';
import includes from 'lodash/includes';
import { PVMediumPurple } from '@pv/common/colors';
import { useLocation } from 'react-router-dom';

const SubscriptionStatusBar = () => {
  const { venues } = useVenues();
  const location = useLocation();
  const { pathname } = location;
  const theme = useTheme();
  const navigate = useNavigate();
  const [showBanner, setShowBanner] = useState(false);
  const HEIGHT = 48;

  useEffect(() => {
    setShowBanner(
      some(venues, (v) =>
        includes(['unpaid', 'past_due'], v.subscription.status)
      )
    );
  }, [venues]);

  const onClick = () => {
    navigate(`/settings/organization`);
  };

  if (pathname.match(/^\/onboarding/)) {
    return null;
  }

  return (
    <>
      {showBanner ? (
        <Toolbar
          sx={{
            backgroundColor: PVMediumPurple,
            height: HEIGHT,
            minHeight: HEIGHT,
            justifyContent: 'center',
          }}
        >
          <Typography color="white" style={{ margin: theme.spacing(0, 2) }}>
            💳{' '}
            <strong style={{ marginLeft: theme.spacing(0.5) }}>
              Subscription payment failed. Please update the payment method on
              file.
            </strong>
          </Typography>
          <Button
            size="small"
            color="secondary"
            sx={{
              marginRight: '16px',
            }}
            onClick={onClick}
            variant="contained"
          >
            Update Card on File
          </Button>
        </Toolbar>
      ) : null}
    </>
  );
};

export default SubscriptionStatusBar;
