import { Box, Button, Grid, Link, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { GlobalStyles } from '@mui/styled-engine';
import React, { useState } from 'react';
import { usePublicConfig } from '@pv/common/providers';
import { Fields } from '@pv/common/components';
import { useAuthErrorMessage } from '@pv/common/hooks';
import { apiHost } from '@pv/common/api';
import { Helmet } from 'react-helmet';
const API_HOST = apiHost(process.env.REACT_APP_PV_ENV);

const Footer = () => {
  const { publicConfig } = usePublicConfig();
  return (
    <Box>
      <Typography variant="body2" style={{ marginTop: '48px' }}>
        Already have an account?{' '}
        <Link
          sx={{
            color: 'secondary.main',
          }}
          underline="hover"
          href={publicConfig?.paths?.newUserSession}
        >
          <Typography
            component="span"
            variant="subtitle2"
            sx={{
              color: 'secondary.main',
            }}
          >
            Sign In
          </Typography>
        </Link>
      </Typography>
    </Box>
  );
};

export default function Page({ email: initialEmail = null }) {
  const theme = useTheme();
  const [email, setEmail] = useState(initialEmail || '');
  const [password, setPassword] = useState('');
  const [submitDisabled, setSubmitDisabled] = useState(false);

  useAuthErrorMessage((auth) => {
    setEmail(auth.params?.email || '');
  });

  return (
    <>
      <GlobalStyles
        styles={{
          html: { height: '100%' },
          body: { backgroundColor: theme.palette.white, height: '100%' },
        }}
      />
      <Helmet>
        <title>Registration | Perfect Venue</title>
      </Helmet>
      <Box
        sx={{
          boxSizing: 'border-box',
          height: { md: 'auto', lg: '100vh' },
          width: '100vw',
          overflowY: 'hidden',
          overflowX: 'hidden',
          display: 'flex',
        }}
        data-cy="sign-up-page"
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            width: { xs: '100%', sm: '100%', md: '100%', lg: '50%' },
            overflowY: 'auto',
            padding: { xs: 2, sm: 4, md: 4, lg: 4 },
            paddingBottom: { xs: 0, sm: 0, md: 0, lg: 6 },
            margin: 0,
          }}
        >
          <Box
            component="img"
            src="/pv-logo-name.png"
            sx={{
              height: 40,
              width: 228,
              marginBottom: { xs: 2, s: 6, md: 8, lg: 10 },
            }}
          />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'start',
              padding: { xs: 3, md: 4 },
              margin: '0 auto',
              width: '100%',

              maxWidth: '448px',
            }}
          >
            <Box
              component="img"
              src="/sign-in.svg"
              sx={{
                width: '100%',
                marginBottom: '40px',
              }}
            />

            <form
              onSubmit={() => setSubmitDisabled(true)}
              id="sign-up-form"
              method="POST"
              action={`${API_HOST}/users`}
            >
              <Typography style={{ marginBottom: '10px' }} variant="h5">
                Create an Account
              </Typography>
              <Typography
                style={{
                  fontSize: '16px',
                  fontWeight: 400,
                  lineHeight: '24px',
                  marginBottom: '20px',
                }}
              >
                Sign up for a 14-day{' '}
                <Typography
                  component="span"
                  style={{
                    fontSize: '16px',
                    fontWeight: 700,
                    lineHeight: '24px',
                    marginBottom: '20px',
                  }}
                >
                  FREE
                </Typography>{' '}
                trial of our Premium Plan
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Fields.PvTextField
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    name="user[email]"
                    label="Email"
                    inputProps={{ autoComplete: 'email', spellCheck: false }}
                    data-cy="sign-up-email"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Fields.PvPasswordField
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    name="user[password]"
                    label="Password"
                    inputProps={{
                      autoComplete: 'new-password',
                      spellCheck: false,
                    }}
                    data-cy="sign-up-password"
                  />
                </Grid>

                <Grid item xs={12}>
                  <Button
                    disabled={submitDisabled}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    color="secondary"
                    data-cy="sign-up-submit"
                  >
                    Start Free Trial
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body2">
                    By creating an account, you agree to our{' '}
                    <Link
                      underline="hover"
                      target="_blank"
                      href={'https://www.perfectvenue.com/terms-of-service'}
                    >
                      <Typography
                        component="span"
                        variant="subtitle2"
                        sx={{
                          color: 'secondary.main',
                        }}
                      >
                        terms of service
                      </Typography>
                    </Link>
                    .
                  </Typography>
                </Grid>
              </Grid>
            </form>
            <Footer />
          </Box>
        </Box>
        <Box
          sx={{
            width: '50%',
            height: '100%',
            backgroundImage: `url('/cocktails.jpg')`,
            backgroundSize: 'cover',
            backgroundAttachment: 'local',
            backgroundRepeat: 'no-repeat',
            display: { xs: 'none', sm: 'none', md: 'none', lg: 'block' },
          }}
          data-cy="sign-up-page-background"
        />
      </Box>
    </>
  );
}
