import React from 'react';
import { Box, Button } from '@mui/material';

export const SectionContainer = ({
  gap = '16px',
  children,
}: {
  gap?: string;
  children: React.ReactNode | React.ReactNode[];
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        margin: '16px',
        gap: gap,
      }}
    >
      {children}
    </Box>
  );
};
export const RowContainer = ({
  gap = '16px',
  children,
}: {
  gap?: string;
  children: React.ReactNode | React.ReactNode[];
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        gap: gap,
      }}
    >
      {children}
    </Box>
  );
};

export const ButtonRowContainer = ({
  children,
}: {
  children: React.ReactNode | React.ReactNode[];
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
        gap: '8px',
      }}
    >
      {children}
    </Box>
  );
};

export const ColumnContainer = ({
  children,
}: {
  children: React.ReactNode | React.ReactNode[];
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '16px',
      }}
    >
      {children}
    </Box>
  );
};

export const HalfRowContainer = ({
  children,
}: {
  children: React.ReactNode | React.ReactNode[];
}) => {
  return (
    <Box
      sx={{
        flexBasis: '50%',
      }}
    >
      {children}
    </Box>
  );
};

export const SmallTextButton = ({
  onClick,
  children,
}: {
  onClick: () => void;
  children: React.ReactNode | React.ReactNode[] | string;
}) => {
  return (
    <Button variant="text" size="small" color="inherit" onClick={onClick}>
      {children}
    </Button>
  );
};

const EventDrawerComponents = {
  SectionContainer,
  RowContainer,
  ColumnContainer,
  ButtonRowContainer,
  HalfRowContainer,
  SmallTextButton,
};

export default EventDrawerComponents;
