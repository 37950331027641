import React, { useMemo } from 'react';
import { Avatar, Badge } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Planner, User } from '@pv/common/graphql/types';

const InboxBadge = styled(Badge)`
  & .MuiBadge-badge {
    height: 12px;
    min-width: 12px;
  }

  & .MuiBadge-standard {
    height: 12px;
    top: 2px;
    left: 22px;
    width: 12px;
  }
`;

export const InboxItemAvatar = ({
  person,
  bgcolor = 'primary.main',
  unreadMessageCount = 0,
}: {
  person: Partial<Planner> | Partial<User>;
  bgcolor?: string;
  unreadMessageCount?: number;
}) => {
  const initials = useMemo(
    () => (person?.fullName ? person.fullName[0] : '').toUpperCase(),
    [person]
  );

  const avatarStyles = { fontWeight: 700, bgcolor };

  return unreadMessageCount && unreadMessageCount > 0 ? (
    <InboxBadge badgeContent="" color="error">
      <Avatar sx={avatarStyles}>{initials}</Avatar>
    </InboxBadge>
  ) : (
    <Avatar sx={avatarStyles}>{initials}</Avatar>
  );
};
