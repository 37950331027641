import { gql, useQuery } from '@apollo/client';
import { PlaidLinkTokenQuery } from '@pv/common/graphql/operations';

const plaidLinkTokenQuery = gql`
  query PlaidLinkToken($clientUserId: ID!) {
    plaidLinkToken(clientUserId: $clientUserId)
  }
`;

export const usePlaidToken = ({ clientUserId }: { clientUserId: string }) => {
  const variables = { clientUserId };
  const skip = !clientUserId;
  const { data } = useQuery<PlaidLinkTokenQuery>(plaidLinkTokenQuery, {
    skip,
    variables,
  });
  return data?.plaidLinkToken;
};
