import React, { ChangeEvent, useState } from 'react';
import { ChevronLeft as BackButtonIcon } from 'react-feather';
import first from 'lodash/first';
import { Box, Button, IconButton, Divider, Grid } from '@mui/material';
import { useVenues } from '../../../../providers/venues';
import { AccountInput, Fields, SidebarHeader } from '@pv/common/components';
import { SaveContactPlanner } from '../types';
import { Planner } from '@pv/common/graphql/types';
import { SearchContact } from './SearchContact';
import { PlannerEventStats } from '../../../../components/PlannerEventStats';
export const EditContact = ({
  planner,
  saveContact,
  onClickExitEdit,
  onClickUnlinkContact,
  onClickMakePrimary,
  onClickSearchResult,
  readOnly = false,
  eventId,
}: {
  planner: Planner;
  saveContact: (planner: SaveContactPlanner) => void;
  onClickExitEdit: () => void;
  onClickUnlinkContact?: () => void;
  onClickMakePrimary?: (plannerId: string) => void;
  onClickSearchResult: (plannerId: string) => void;
  readOnly?: boolean;
  eventId?: string;
}) => {
  const { venues } = useVenues();
  const organizationId = first(venues)?.organization?.id;

  const [firstName, setFirstName] = useState(planner.firstName || '');
  const [lastName, setLastName] = useState(planner.lastName || '');
  const [email, setEmail] = useState(planner.email || '');
  const [phone, setPhone] = useState(planner.phone || '');
  const [accountName, setAccountName] = useState(planner?.account?.name || '');
  const [notes, setNotes] = useState(planner.notes || '');

  const cancel = () => {
    setFirstName(planner.firstName || '');
    setLastName(planner.lastName || '');
    setEmail(planner.email || '');
    setPhone(planner.phone || '');
    setAccountName(planner.account?.name || '');
    setNotes(planner.notes || '');
    onClickExitEdit();
  };

  const isFormInputValid = () => {
    return firstName;
  };

  const handleSaveContact = (e: any) => {
    e.preventDefault();
    // don't save if required fields are missing
    if (!isFormInputValid()) {
      return;
    }
    if (readOnly) {
      return;
    }

    saveContact({
      id: planner.id,
      firstName,
      lastName,
      email,
      phone,
      accountName,
      notes,
    });
  };

  const handleUnlinkContact = () => {
    if (onClickUnlinkContact) {
      onClickUnlinkContact();
    }
    onClickExitEdit();
  };

  const titleText = planner.id ? 'Contact' : 'Add Contact';
  return (
    <form>
      <Box
        data-cy="event-drawer-contact-edit"
        sx={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '16px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <IconButton size="small" onClick={cancel} aria-label="cancel">
              <BackButtonIcon fontSize="inherit" />
            </IconButton>
            <SidebarHeader title={titleText} />
          </Box>
          <Button
            size="small"
            type="submit"
            variant="contained"
            color="secondary"
            disabled={readOnly || !isFormInputValid()}
            onClick={handleSaveContact}
            data-cy="event-drawer-contact-edit-save"
          >
            Save
          </Button>
        </Box>
        <Divider />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '16px',
            padding: '16px',
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <SearchContact
                disableSearch={readOnly || !!planner.id}
                query={firstName}
                onClickSearchResult={onClickSearchResult}
                eventId={eventId}
              >
                <Fields.PvTextField
                  variant="outlined"
                  autoFocus
                  inputProps={{ autoComplete: 'off' }}
                  required
                  label="First Name"
                  name="firstName"
                  value={firstName}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setFirstName(e.target.value)
                  }
                  disabled={readOnly}
                />
              </SearchContact>
            </Grid>
            <Grid item xs={6}>
              <Fields.PvTextField
                variant="outlined"
                inputProps={{ autoComplete: 'off' }}
                label="Last Name"
                name="lastName"
                value={lastName}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setLastName(e.target.value)
                }
                disabled={readOnly}
              />
            </Grid>
          </Grid>
          <Fields.PvTextField
            variant="outlined"
            inputProps={{ autoComplete: 'off' }}
            label="Email"
            name="email"
            type="email"
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setEmail(e.target.value)
            }
            value={email}
            disabled={readOnly}
          />

          <Fields.PvPhoneField
            variant="outlined"
            inputProps={{ autoComplete: 'off' }}
            label="Phone"
            name="phone"
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setPhone(e.target.value)
            }
            value={phone}
            disabled={readOnly}
          />

          <AccountInput
            organizationId={organizationId}
            inputProps={{ autoComplete: 'off' }}
            variant="outlined"
            label="Account"
            name="account"
            onChange={setAccountName}
            value={accountName}
            disabled={readOnly}
          />

          <Fields.PvTextField
            variant="outlined"
            inputProps={{ autoComplete: 'off' }}
            label="Notes"
            name="notes"
            multiline
            minRows={4}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setNotes(e.target.value)
            }
            value={notes}
            disabled={readOnly}
          />

          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            {onClickUnlinkContact && (
              <Button
                variant="outlined"
                color="secondary"
                onClick={handleUnlinkContact}
                disabled={readOnly}
              >
                Remove
              </Button>
            )}
            {onClickMakePrimary && planner.id && (
              <Button
                variant="outlined"
                color="primary"
                onClick={() => onClickMakePrimary(planner.id)}
                disabled={readOnly}
              >
                Make Primary
              </Button>
            )}
          </Box>

          {planner.id && <PlannerEventStats plannerId={planner.id} />}
        </Box>
      </Box>
    </form>
  );
};
