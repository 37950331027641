import React from 'react';
import { useTheme, IconButton, useMediaQuery } from '@mui/material';
import { Search } from 'react-feather';
import HelpMenu from '../HelpMenu';
import AccountButton from './AccountButton';
import CannyButton from './CannyButton';
import VenueDropDown from './VenueDropDown';
import { GetStarted } from './GetStarted';
import { useOnboarding } from '@pv/core/src/providers/onboarding';

const LoggedInElements = ({
  setSearchOpen,
}: {
  setSearchOpen: (open: boolean) => void;
}) => {
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const mediumScreen = useMediaQuery(theme.breakpoints.down('md'));

  const { showQuickStart } = useOnboarding();

  return (
    <>
      {mediumScreen && <div style={{ flexGrow: 1 }} />}
      <IconButton
        aria-label="Search"
        title="Search"
        onClick={() => setSearchOpen(true)}
        style={{ padding: '10px' }}
      >
        <span style={{ color: theme.palette.common.white }}>
          <Search />
        </span>
      </IconButton>
      {!smallScreen && <HelpMenu />}
      {!mediumScreen && <CannyButton client={window.Canny} />}
      <VenueDropDown />
      {!smallScreen && <AccountButton />}
      {showQuickStart && <GetStarted />}
    </>
  );
};

export default LoggedInElements;
