import { Grid, IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { useTheme } from '@material-ui/styles';
import first from 'lodash/first';
import moment from 'moment';
import React from 'react';
import { Fields } from '@pv/common/components';

import { useVenues } from '../../providers/venues';

const EventTimeEdit = ({
  startDate,
  startOffset,
  endDate,
  endOffset,
  setStartDate,
  setStartOffset,
  setEndDate,
  setEndOffset,
  autoFocus,
  onRemove,
  multiDayEnabled = false,
}) => {
  const { venues } = useVenues();
  const venue = first(venues);
  const theme = useTheme();
  const timeInterval = 15;
  const startTimeStart = venue?.eventStartOffset;
  const startTimeEnd = venue?.eventEndOffset - timeInterval;

  const endTimeStart = venue?.eventStartOffset + timeInterval;
  const endTimeEnd = venue?.eventEndOffset;

  const startDateLabel = multiDayEnabled ? 'Start Date' : 'Date';
  const dateXs = multiDayEnabled ? 6 : 12;
  const timeXs = multiDayEnabled ? 6 : 6;
  const dateSm = multiDayEnabled ? 6 : 6;
  const timeSm = multiDayEnabled ? 6 : 3;

  return (
    <div style={{ display: 'flex' }}>
      <div style={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
          <Grid item xs={dateXs} sm={dateSm}>
            <Fields.PvDateField
              fullWidth
              name="startDate"
              label={startDateLabel}
              autoFocus={autoFocus}
              onChange={(d) => setStartDate(d)}
              value={startDate && moment(startDate)}
              testId="create-event-date-text-field"
            />
          </Grid>
          <Grid item xs={timeXs} sm={timeSm}>
            <Fields.PvTimeField
              label="Start"
              name="startTime"
              start={startTimeStart}
              end={startTimeEnd}
              value={startOffset || ''}
              onChange={(e) => setStartOffset(e.target.value)}
              selectTestId="create-event-start-time-select"
            />
          </Grid>
          {multiDayEnabled && (
            <Grid item xs={dateXs} sm={dateSm}>
              <Fields.PvDateField
                fullWidth
                name="endDate"
                label="End Date"
                onChange={(d) => setEndDate(d)}
                value={endDate && moment(endDate)}
              />
            </Grid>
          )}
          <Grid item xs={timeXs} sm={timeSm}>
            <Fields.PvTimeField
              label="End"
              name="endTime"
              start={endTimeStart}
              end={endTimeEnd}
              value={endOffset || ''}
              onChange={(e) => setEndOffset(e.target.value)}
              selectTestId="create-event-end-time-select"
            />
          </Grid>
        </Grid>
      </div>
      {startDate && onRemove && (
        <div style={{ flexShrink: 1, marginLeft: theme.spacing(2) }}>
          <IconButton onClick={onRemove}>
            <DeleteIcon />
          </IconButton>
        </div>
      )}
    </div>
  );
};

export default EventTimeEdit;
