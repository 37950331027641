import React from 'react';
import { useVenues } from './venues';
import { gql, useQuery } from '@apollo/client';

interface Owner {
  id: string;
  fullName: string;
}

interface OwnersContextProps {
  owners: Owner[] | null;
  refetchOwners: () => void;
}

const OwnersContext = React.createContext<OwnersContextProps>({
  owners: null,
  refetchOwners: () => {},
});

const EventOwnersQuery = gql`
  query EventOwners($venueIds: [ID!]!) {
    venues(ids: $venueIds) {
      id
      eventOwners {
        id
        fullName
      }
    }
  }
`;

export const useOwners = () => React.useContext(OwnersContext);

export const OwnersProvider = ({ children }: any) => {
  const [owners, setOwners] = React.useState<null | Owner[]>(null);
  const { venues } = useVenues();
  const [premiumVenueIds, setPremiumVenueIds] = React.useState<string[]>([]);

  React.useEffect(() => {
    if (venues) {
      const premiumVenues = venues.filter((venue: any) => {
        return venue.featureScope.premiumFeaturesEnabled;
      });

      const premiumVenueIds = premiumVenues.map((venue: any) => {
        return venue.id;
      });

      setPremiumVenueIds(premiumVenueIds);
      if (premiumVenueIds.length === 0) {
        setOwners([]);
      }
    }
  }, [venues]);

  const { data, refetch } = useQuery(EventOwnersQuery, {
    variables: { venueIds: premiumVenueIds },
    skip: !premiumVenueIds.length,
  });

  React.useEffect(() => {
    if (data) {
      const owners = data.venues
        .map((venue: any) => {
          return venue.eventOwners;
        })
        .flat();
      setOwners(owners);
    }
  }, [data]);

  return (
    <OwnersContext.Provider value={{ owners, refetchOwners: refetch }}>
      {children}
    </OwnersContext.Provider>
  );
};

export default OwnersProvider;
