import React, { useEffect, useState } from 'react';
import validator from 'validator';
import { enqueueSnackbar } from 'notistack';
import { CircularProgress } from '@material-ui/core/index';
import { useUpdatePlannerMutation } from '@pv/common/graphql';
import { ViewContact } from './ViewContact';
import { EditContact } from './EditContact';
import { ContactAreaPlannerType, SavePlannerMutationType } from '../types';
import { Planner } from '@pv/common/graphql/types';
import ContactDrawer from './ContactDrawer';

type ContactItemPropType = {
  planner: ContactAreaPlannerType;
  eventId?: string;
  onSave: ({ variables }: SavePlannerMutationType) => Promise<void>;
  onUnlink?: (plannerId?: string) => void;
  resetAdditionalContacts: () => void;
  readOnly?: boolean;
  makePrimary?: (plannerId: string) => void;
};
export const ContactItem = ({
  planner,
  eventId,
  onSave,
  onUnlink,
  resetAdditionalContacts,
  readOnly = false,
  makePrimary,
}: ContactItemPropType) => {
  let initialView = 'edit';
  if (planner.id) {
    initialView = 'view';
  } else if (planner.firstName) {
    initialView = 'edit';
  }
  const [contactView, setContactView] = useState(initialView);
  const [plannerForEdit, setPlannerForEdit] =
    useState<ContactAreaPlannerType>(planner);

  useEffect(() => {
    setPlannerForEdit(planner);
  }, [planner, eventId]);

  const [updatePlanner, { loading: isLoading }] = useUpdatePlannerMutation({
    onNoErrorsCompleted: () => {},
  });

  const saveContact = ({
    id,
    firstName,
    lastName,
    email,
    phone,
    accountName,
    notes,
  }: ContactAreaPlannerType) => {
    if (email && !validator.isEmail(email)) {
      enqueueSnackbar('Email is invalid', { variant: 'error' });
      return;
    }
    if (id) {
      const input = {
        id,
        firstName,
        lastName,
        email,
        phone,
        accountName,
        notes,
      };
      const variables = { input };
      updatePlanner({ variables });
      viewContact();
    } else {
      const input = {
        id: eventId,
        planner: {
          firstName,
          lastName,
          email,
          phone,
          accountName,
          notes,
        },
      };
      const variables = { input };
      onSave({ variables });
      viewContact();
    }
  };

  const viewContact = () => {
    if (planner?.id) {
      setContactView('view');
    } else {
      resetAdditionalContacts();
    }
  };

  const onClickSearchResult = (plannerId: string) => {
    const input = {
      id: eventId,
      plannerId,
    };
    onSave({ variables: { input } });
  };

  return (
    <div data-cy="event-page-contacts">
      {isLoading ? (
        <CircularProgress />
      ) : (
        <>
          {planner.id && (
            <ViewContact
              onClickEditContact={() => setContactView('edit')}
              planner={planner}
              readOnly={readOnly}
            />
          )}

          {contactView === 'edit' && (
            <ContactDrawer
              isOpen={contactView === 'edit'}
              onClose={viewContact}
            >
              <EditContact
                planner={plannerForEdit as Planner}
                saveContact={saveContact}
                onClickUnlinkContact={onUnlink}
                onClickExitEdit={viewContact}
                onClickMakePrimary={makePrimary}
                onClickSearchResult={onClickSearchResult}
                eventId={eventId}
                readOnly={readOnly}
              />
            </ContactDrawer>
          )}
        </>
      )}
    </div>
  );
};
