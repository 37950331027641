import { NetworkStatus, QueryHookOptions } from '@apollo/client';
import { createContext, Dispatch, SetStateAction } from 'react';
import { ActiveEventsQuery } from '@pv/common/graphql/operations';
import { TableState } from './types';

export interface ActiveEventsContextType {
  refetch: () => void;
  fetchMore: (options: QueryHookOptions) => void;
  networkStatus: NetworkStatus;
  loading: boolean;
  tableState: TableState;
  setTableState: Dispatch<SetStateAction<TableState>>;
  data?: ActiveEventsQuery;
}

export const ActiveEventsContext = createContext<ActiveEventsContextType>({
  refetch: () => {},
  fetchMore: () => {},
  loading: false,
  networkStatus: NetworkStatus.ready,
  tableState: {},
  setTableState: () => {},
});
