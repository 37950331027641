import React from 'react';
import { ListItemButton, ListItemIcon, ListItemText } from '@mui/material';

export const HamburgerMenuItem = ({
  icon,
  text,
  onClick,
}: {
  icon: React.ReactNode;
  text: string;
  onClick: () => void;
}) => {
  return (
    <ListItemButton alignItems="flex-start" onClick={onClick}>
      <ListItemIcon
        sx={{
          minWidth: '32px',
        }}
      >
        {icon}
      </ListItemIcon>
      <ListItemText
        disableTypography
        sx={{
          paddingTop: '4px',
          paddingBottom: '4px',
          paddingLeft: '6px',
          fontSize: '16px',
          color: 'primary.main',
        }}
        primary={text}
      />
    </ListItemButton>
  );
};
