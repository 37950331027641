import { ReactElement, useCallback, useEffect, useState } from 'react';

type SaveHandler<T> = (newValue: T) => void;

// AutoSave component
// Use this to provide value, onChange props to form fields via renderField
export function AutoSave<CachedValueType = string>({
  onSave,
  value,
  renderField,
}: {
  onSave: SaveHandler<CachedValueType>;
  value: CachedValueType;
  renderField: (
    value: CachedValueType,
    onChange: SaveHandler<CachedValueType>
  ) => ReactElement;
}) {
  const [localState, setLocalState] = useState<CachedValueType>(value);

  useEffect(() => {
    setLocalState(value);
  }, [value]);

  const onChange = useCallback(
    (value) => {
      setLocalState(value);
      onSave(value);
    },
    [onSave]
  );

  return renderField(localState, onChange);
}
