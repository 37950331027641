import React, { useEffect } from 'react';
import { Outlet, useNavigate, useSearchParams } from 'react-router-dom';
import { useUserAuth } from '../../providers/auth';
import { apiHost } from '@pv/common/api';

const API_HOST = apiHost(process.env.REACT_APP_PV_ENV);

export default () => {
  const navigate = useNavigate();
  const { user } = useUserAuth();
  const [searchParams, _] = useSearchParams();
  const returnTo = searchParams.get('return_to');

  useEffect(() => {
    if (user) {
      if (returnTo) {
        window.location.replace(`${API_HOST}${returnTo}`);
      } else {
        navigate('/');
      }
    }
  }, [user]);

  if (user) {
    return null;
  }

  return <Outlet />;
};
