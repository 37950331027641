import { Box, Fade, useMediaQuery, useTheme } from '@mui/material';
import React, { Suspense, useEffect } from 'react';

import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { PageLoading } from '@pv/common/components';
import { useVenues } from '../../providers/venues';
import DesktopNavBar from './DesktopNavBar';
import {
  MobileNavBar,
  isMobileNavBarVisible,
  mobileAppbarHeight,
} from './MobileNavBar';
import { LockedOut } from '../../pages/LockedOut';
import { useUserAuth } from '../../providers/auth';
import { useFlows } from '@frigade/react';
import { FLOW_IDS } from '../Frigade/config';
import { gql, useQuery } from '@apollo/client';
import some from 'lodash/some';
import includes from 'lodash/includes';

const USER_NEEDS_ONBOARDING = gql`
  query UserNeedsOnboarding {
    userNeedsOnboarding
  }
`;

const Dashboard = () => {
  const { venues, loading } = useVenues();
  const { user } = useUserAuth();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down('md'));
  const showMobileNavBar = smallScreen && isMobileNavBarVisible(pathname);

  const { getFlowStatus, isLoading } = useFlows();
  const hasCompletedOnboarding =
    getFlowStatus(FLOW_IDS.ONBOARDING_FORM) === 'COMPLETED_FLOW';
  const { data } = useQuery(USER_NEEDS_ONBOARDING);
  const userNeedsOnboarding = data?.userNeedsOnboarding;

  const isOnSettings = pathname.includes('settings');
  const lockedOutVenues = venues?.filter((v) => v.subscription.lockOut);
  const noActiveVenues = user.organizationMembership && !loading && !venues;
  const isLockedOut =
    !isOnSettings && (noActiveVenues || lockedOutVenues?.length > 0);

  const venue = venues?.[0];
  const tier = venue?.subscription?.tier;

  const PRIMARY_APP_BAR_HEIGHT = 60;
  const STATUS_APP_BAR_HEIGHT = 64;
  const SECONDARY_APP_BAR_HEIGHT = 42;

  const isUnpaidOrPastDue = some(venues, (v) =>
    includes(['unpaid', 'past_due'], v.subscription.status)
  );
  const isOnTrialOrStarter = tier === 'trial' || tier === 'starter';
  const isOnReports = pathname.match(/^\/reports/);

  const getPaddingTopPx = () => {
    let padding = PRIMARY_APP_BAR_HEIGHT;
    if (isOnTrialOrStarter || isUnpaidOrPastDue) {
      padding += STATUS_APP_BAR_HEIGHT;
    }
    if (isOnReports) {
      padding += SECONDARY_APP_BAR_HEIGHT;
    }
    return `${padding}px`;
  };

  const getPaddingBottomPx = () => {
    return showMobileNavBar ? `${mobileAppbarHeight}px` : '0px';
  };

  useEffect(() => {
    if (userNeedsOnboarding && !isLoading && !hasCompletedOnboarding) {
      navigate('/onboarding');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasCompletedOnboarding, isLoading, userNeedsOnboarding]);

  return (
    <div>
      <DesktopNavBar />
      <Suspense fallback={<PageLoading />}>
        <Fade in={!!user}>
          <main>
            <Box
              sx={{
                paddingTop: getPaddingTopPx(),
                paddingBottom: getPaddingBottomPx(),
              }}
            >
              {isLockedOut ? (
                <LockedOut lockedOutVenues={lockedOutVenues} />
              ) : (
                <Outlet />
              )}
            </Box>
          </main>
        </Fade>
      </Suspense>
      {showMobileNavBar && <MobileNavBar />}
    </div>
  );
};

export default Dashboard;
