import { Toolbar, Button, Typography } from '@mui/material';
import first from 'lodash/first';
import max from 'lodash/max';
import some from 'lodash/some';
import moment from 'moment';
import pluralize from 'pluralize';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import SearchResults from '../../SearchResults';
import { useVenues } from '../../../providers/venues';
import { PVMediumPurple } from '@pv/common/colors';
import { useUserAuth } from '../../../providers/auth';
import { useUserAgent } from '@pv/core/src/hooks/useUserAgent';

const FreeTrialAppBar = () => {
  const { user } = useUserAuth();
  const { venues } = useVenues();
  const { isWeb } = useUserAgent();
  const venue = first(venues);
  const navigate = useNavigate();
  const [searchOpen, setSearchOpen] = useState(false);
  const tier = venue?.subscription?.tier;
  let showBanner = tier === 'trial' || tier === 'starter';
  if (some(venues, (v) => v.customerStartedAt)) {
    showBanner = false;
  }
  const HEIGHT = 48;

  const onClickBuyNow = () => {
    navigate(`/pricing`);
  };

  if (!showBanner) {
    return null;
  }

  let daysLeft = moment(venue?.subscription?.endDate)
    .startOf('day')
    .diff(moment().startOf('day'), 'days');
  daysLeft = max([0, daysLeft]);

  return (
    <>
      <Toolbar
        sx={{
          backgroundColor: PVMediumPurple,
          height: HEIGHT,
          minHeight: HEIGHT,
          justifyContent: 'center',
        }}
      >
        <Typography
          color="primary.contrastText"
          sx={{
            flex: { xs: '2 1', sm: 'initial' },
            marginRight: '12px',
          }}
        >
          <strong>{daysLeft}</strong> {pluralize('Day', daysLeft)} Left on Your
          Free Trial
        </Typography>
        {isWeb && (
          <Button
            size="small"
            color="secondary"
            sx={{ marginRight: '8px' }}
            onClick={onClickBuyNow}
            variant="contained"
          >
            Upgrade
          </Button>
        )}

        <Button
          size="small"
          color="info"
          variant="contained"
          data-cy="app-bar-contact-sales-button"
          target="_blank"
          href="https://meetings.hubspot.com/luke486/demo-request-from-in-the-product"
        >
          Book a Demo
        </Button>
      </Toolbar>
      <SearchResults open={searchOpen} onClose={() => setSearchOpen(false)} />
    </>
  );
};

export default FreeTrialAppBar;
