import React from 'react';
import { Chip, SxProps, useTheme } from '@mui/material';
import { startCase } from 'lodash';
import { ValidPlans } from '../types';

export interface PlanChipProps {
  plan: ValidPlans;
  fullWidth?: boolean;
  size?: 'small' | 'medium';
}

const baseStyles = {
  fontWeight: 600,
  color: 'primary.contrastText',
  position: 'relative',
  '&:focus, &:active, &:hover': {
    fontWeight: 600,
  },
};

const PlanChip = ({ plan, fullWidth, size = 'small' }: PlanChipProps) => {
  const theme = useTheme();
  const smallScreen = theme.breakpoints.down('sm');
  const fontSize = smallScreen ? '12px' : '14px';

  const styles: Record<ValidPlans, SxProps> = {
    basic: {
      ...baseStyles,
      fontSize,
      bgcolor: 'primary.pink',
      '&:focus, &:active, &:hover': {
        bgcolor: 'primary.pink',
      },
    },
    starter: {
      ...baseStyles,
      fontSize,
      color: 'primary.main',
      bgcolor: 'primary.hoverNavy',
      '&:focus, &:active, &:hover': {
        bgcolor: 'primary.hoverNavy',
      },
    },
    professional: {
      ...baseStyles,
      fontSize,
      bgcolor: 'primary.main',
      '&:focus, &:active, &:hover': {
        bgcolor: 'primary.main',
      },
    },
    premium: {
      ...baseStyles,
      fontSize,
      bgcolor: 'primary.premium',
      '&:focus, &:active, &:hover': {
        bgcolor: 'primary.premium',
      },
    },
  };

  const sx = {
    ...styles[plan],
    width: fullWidth ? '100%' : 'auto',
  };

  return (
    <Chip label={startCase(plan)} sx={sx} className="plan-chip" size={size} />
  );
};

export default PlanChip;
