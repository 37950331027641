import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Button, Drawer } from '@material-ui/core';
import EventDrawer from './EventDrawer';
import { useNavigate } from 'react-router-dom';
import EventProvider from '../providers/event';

const drawerWidth = 370;

const useStyles = makeStyles((theme) => ({
  root: {
    paddingBottom: theme.spacing(6),
  },
  paper: {
    padding: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      padding: 0,
    },
  },
  drawer: {
    zIndex: 1,
    width: drawerWidth,
    maxWidth: '100%',
  },
  drawerPaper: {
    width: drawerWidth,
    maxWidth: '100%',
  },
  drawerContainer: {
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
  },
}));

export const EventPreviewDrawer = ({
  isDrawerOpen,
  onClosePreview,
  onEventUpdate,
  eventId,
}: {
  isDrawerOpen: boolean;
  onClosePreview: () => void;
  onEventUpdate?: () => {};
  eventId: string;
}) => {
  const classes = useStyles();
  const navigate = useNavigate();

  return (
    <Drawer
      open={isDrawerOpen}
      anchor="right"
      variant="temporary"
      className={classes.drawer}
      classes={{ paper: classes.drawerPaper }}
      onClose={onClosePreview}
      ModalProps={{ disableEnforceFocus: true }}
    >
      <EventProvider eventId={eventId}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            padding: '12px',
            margin: '0 4px 8px',
            alignItems: 'center',
          }}
        >
          <Button color="default" onClick={onClosePreview} variant="contained">
            Close
          </Button>
          <Button
            style={{
              margin: '0 6px',
            }}
            color="secondary"
            onClick={() => navigate(`/events/${eventId}`)}
            variant="contained"
          >
            Open
          </Button>
        </div>
        <EventDrawer onEventUpdate={onEventUpdate} />
      </EventProvider>
    </Drawer>
  );
};
