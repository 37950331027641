import React from 'react';
import { gql, useQuery } from '@apollo/client';
import { CircularProgress } from '@mui/material';

import { EventStatsOverview } from './EventStats';
import { EventsList, Event } from './EventsList';

const plannerQuery = gql`
  query PlannerEventStats($id: ID) {
    planner(id: $id) {
      id
      eventCount
      upcomingEventCount
      totalSpend
      averageSpend
      events {
        id
        status
        name
        startDate
      }
    }
  }
`;
interface PlannerEventStatsProps {
  plannerId: string;
}

export const PlannerEventStats = ({ plannerId }: PlannerEventStatsProps) => {
  const variables = { id: plannerId };
  const { data, loading } = useQuery(plannerQuery, {
    variables,
  });

  const planner = data?.planner || {};
  const events = planner.events || [];

  if (loading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <CircularProgress />
      </div>
    );
  }
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <EventStatsOverview
        eventCount={planner.eventCount}
        upcomingEventCount={planner.upcomingEventCount}
        totalSpend={planner.totalSpend}
        averageSpend={planner.averageSpend}
      />
      <EventsList
        events={events.filter((ev: Event) => ev.status != 'cancelled')}
      />
    </div>
  );
};
