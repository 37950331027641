import { useFlows } from '@frigade/react';
import { Box, Button, useTheme } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FLOW_IDS } from '../../../../Frigade/config';

export const GetStarted = () => {
  const navigate = useNavigate();
  const { getNumberOfSteps, getNumberOfStepsCompleted } = useFlows();

  const stepCount = getNumberOfSteps(FLOW_IDS.QUICKSTART_GUIDE);
  const stepsCompleted = getNumberOfStepsCompleted(FLOW_IDS.QUICKSTART_GUIDE);
  const theme = useTheme();
  return (
    <div>
      <Button
        onClick={() => {
          navigate('/settings');
        }}
        sx={{
          marginLeft: '20px',
          borderRadius: '25px',
          padding: '6px 12px 6px 12px',
          backgroundColor: theme.palette.common.white,
          color: '#272B64',
          '&:hover': {
            backgroundColor: 'info.dark',
          },
        }}
        variant="contained"
      >
        👋 Get Started
      </Button>
      <Box
        sx={{
          marginLeft: '-10px',
          position: 'relative',
          float: 'right',
          backgroundColor: 'secondary.main',
          height: '22px',
          width: '22px',
          textAlign: 'center',
          borderRadius: '30px',
          fontWeight: '700',
          fontSize: '14px',
        }}
      >
        {stepCount - stepsCompleted}
      </Box>
    </div>
  );
};
