import React, { useEffect, useMemo } from 'react';
import { IconButton, useTheme } from '@mui/material';
import { Zap } from 'react-feather';

interface Props {
  client: Window['Canny'];
}

const CannyButton = ({ client }: Props) => {
  const theme = useTheme();

  useEffect(() => {
    if (client) {
      client('initChangelog', {
        appID: '5f2d89d58c253106b02a15e2',
        position: 'bottom',
        align: 'right',
      });
    }
  }, [client]);

  const sx = useMemo(
    () => ({
      // See https://developers.canny.io/install/changelog/customizations
      '& .Canny_BadgeContainer .Canny_Badge': {
        top: '7px',
        right: '8px',
        backgroundColor: theme.palette.secondary.main,
        border: `1px solid ${theme.palette.primary.main}`,
      },
    }),
    [theme.palette]
  );

  return (
    <IconButton data-canny-changelog sx={sx}>
      <span style={{ color: theme.palette.common.white }}>
        <Zap />
      </span>
    </IconButton>
  );
};

export default CannyButton;
