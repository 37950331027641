import { Grid, Paper, Box } from '@material-ui/core';
import { Helmet } from 'react-helmet';
import { makeStyles, useTheme } from '@material-ui/styles';
import { useMediaQuery } from '@material-ui/core';
import React from 'react';
import Logo from './Logo';

const useStyles = makeStyles(() => ({
  root: {
    outline: 'solid 1px red',
    height: '100vh',
    width: '100vw',
    overflow: 'hidden',
    backgroundColor: 'white',
  },
  wrapper: {
    outline: 'solid 1px blue',
    height: '100%',
    width: '100%',
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  footerWrapper: {
    marginBottom: 200,
  },
  paper: {
    padding: 20,
    boxShadow: '0px 1px 40px rgba(0, 0, 0, 0.05)',
  },
}));

export const DevisePage = ({ children, title, footer, maxPaperWidth }) => {
  const classes = useStyles();
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div className={classes.root}>
        <div className={classes.wrapper}>
          <Grid container spacing={2} justify="center">
            <Grid item xs={12} sm={8} md={6}>
              <Grid container spacing={2} justify="center">
                <Grid item xs={12}>
                  <Box
                    style={{
                      marginTop: smallScreen ? 65 : 120,
                      marginBottom: smallScreen ? 56 : 60,
                    }}
                  >
                    <Logo />
                  </Box>
                </Grid>

                <Grid item xs={12}>
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Paper
                      className={[classes.paper]}
                      style={{ maxWidth: maxPaperWidth ?? 540 }}
                    >
                      {children}
                    </Paper>
                  </div>
                </Grid>

                {footer && (
                  <Grid item xs={12}>
                    <div className={classes.footerWrapper}>{footer}</div>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </div>
      </div>
    </>
  );
};
