export enum StatusColors {
  pending = 'primary.gray',
  lead = 'primary.yellow',
  qualified = 'primary.orange',
  proposal_sent = 'primary.purple',
  confirmed = 'success.light',
  balance_due = 'primary.pink',
  completed = 'primary.main',
  lost = 'primary.lightGray',
  canceled = 'primary.lightGray',
}
