import React from 'react';
import { Box, Drawer } from '@mui/material';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: 485,
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
}));

interface ContactDrawerProps {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
}

const ContactDrawer = ({ isOpen, onClose, children }: ContactDrawerProps) => {
  const classes = useStyles();
  return (
    <Drawer
      open={isOpen}
      onClose={onClose}
      anchor="right"
      variant="temporary"
      sx={{ zIndex: 1400 }}
    >
      <Box className={classes.drawer}>{children}</Box>
    </Drawer>
  );
};

export default ContactDrawer;
