import { useLocation, Link as RouterLink } from 'react-router-dom';
import { Chip } from '@mui/material';

const PrimaryAppBarButton = ({ label, name, href }) => {
  const location = useLocation();
  const { pathname } = location;
  let matched = pathname.match(`^/${name}`);
  let selected = matched && matched[0] === `/${name}`;

  if (pathname.match(/\/events\/\d+/)) {
    selected = false;
  }

  return (
    <Chip
      label={label}
      to={href}
      component={RouterLink}
      sx={{
        fontSize: '16px',
        cursor: 'pointer',
        color: 'primary.contrastText',
        background: selected ? 'rgba(247, 240, 237, 0.25)' : 'none',
        '&:hover': {
          background: selected ? 'rgba(247, 240, 237, 0.25)' : 'none',
        },
        '&:not(:last-child)': {
          marginLeft: '12px',
        },
      }}
    />
  );
};

export default PrimaryAppBarButton;
