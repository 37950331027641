import { Box } from '@mui/material';
import React from 'react';
import sortBy from 'lodash/sortBy';
import filter from 'lodash/filter';
import map from 'lodash/map';
import { Fields } from '@pv/common/components';

import { useUserFieldDefinitionsQuery } from '@pv/common/graphql';
import { useEvent } from '../providers/event';
import { SectionContainer } from '../events/EventDrawer/components';
import { AutoSave } from './AutoSave';

const CustomFieldArea = ({ save }) => {
  const { eventVenue, event } = useEvent();
  const canUpdateEvent = event?.canUpdate?.value;
  const variables = { venueIds: [eventVenue?.id] };
  const { data } = useUserFieldDefinitionsQuery({
    variables,
    skip: !eventVenue,
  });
  let userFieldDefinitions = data?.userFieldDefinitions;
  userFieldDefinitions = sortBy(userFieldDefinitions, ['index']);
  userFieldDefinitions = filter(
    userFieldDefinitions,
    (ufd) => !ufd.systemManaged
  );

  const onChangeUserField = (name, value) => {
    const uf = { ...event.userFields };
    uf[name] = value;
    save({ venueUserFields: uf }, { userFields: uf });
  };

  return (
    <SectionContainer>
      {map(userFieldDefinitions, (ufd) => {
        return (
          <Box key={ufd.id}>
            <AutoSave
              value={event.userFields[ufd.userFieldKey] || ''}
              onSave={(value) => {
                onChangeUserField(ufd.userFieldKey, value);
              }}
              renderField={(value, onChange) => (
                <Fields.PvCustomField
                  label={ufd.name}
                  variant="outlined"
                  userFieldDefinition={ufd}
                  value={value}
                  onChange={(value) => onChange(value)}
                  disabled={!canUpdateEvent}
                />
              )}
            />
          </Box>
        );
      })}
    </SectionContainer>
  );
};

export default CustomFieldArea;
