import { LicenseInfo as MuiXLicenseInfo } from '@mui/x-license-pro';

const MUI_X_LICENSE_KEY =
  '5fe21ce4d8132ae2a31001e432596f2cTz05NDE1MixFPTE3NTIzNTc5NDEwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=';

if (MUI_X_LICENSE_KEY) MuiXLicenseInfo.setLicenseKey(MUI_X_LICENSE_KEY);

export const MuiXLicense = () => {
  return null;
};
