import React, { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { useUserAuth } from '../../providers/auth';
import useCannyClient from '../../hooks/useCannyClient';

export default () => {
  const navigate = useNavigate();
  const { user } = useUserAuth();
  const currentUrl = window.location.href;

  useCannyClient(window.Canny);

  useEffect(() => {
    if (!user) {
      navigate(`/users/sign_in?return_to=${currentUrl}`);
    }
  }, [user]);

  if (!user) {
    return null;
  }

  return <Outlet />;
};
