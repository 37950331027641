import { makeStyles } from '@material-ui/styles';
import { danger, orange, pink, purple, success, warn } from '@pv/common/colors';

const statusHeight = 80;
const fontWeight = 500;

export const useEventsStyles = makeStyles((theme) => ({
  avatar: {
    fontSize: 20,
    fontWeight: 700,
  },
  green: {
    color: success.textColor,
  },
  warn: {
    color: warn.textColor,
  },
  danger: {
    color: danger.textColor,
  },
  statusBar: {
    display: 'flex',
    boxShadow: '0px 1px 20px rgba(0, 0, 0, 0.05)',
  },
  arrow: {
    width: 0,
    height: 0,
    borderTop: `${statusHeight / 2}px solid transparent`,
    borderBottom: `${statusHeight / 2}px solid transparent`,
    borderLeft: `${statusHeight / 3.5}px solid`,
    borderLeftColor: theme.palette.divider,
    marginRight: -statusHeight / 2,
    zIndex: 100,
  },
  activeArrow: {},
  innerArrow: {
    position: 'relative',
    top: -(statusHeight / 2) + 2,
    left: -(statusHeight / 3.5),
    width: 0,
    height: 0,
    borderTop: `${statusHeight / 2 - 2}px solid transparent`,
    borderBottom: `${statusHeight / 2 - 2}px solid transparent`,
    borderLeft: `${statusHeight / 3.5 - 2}px solid`,
    borderLeftColor: theme.palette.common.white,
  },
  activeInnerArrow: {
    borderLeftColor: theme.palette.primary.main,
  },
  statusBox: {
    border: `1px solid ${theme.palette.divider}`,
    padding: theme.spacing(1),
    paddingLeft: theme.spacing(4),
    width: '100%',
    height: statusHeight,
    backgroundColor: theme.palette.common.white,
    zIndex: 99,
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
    justifyContent: 'center',
  },
  roundedLeftStatusBox: {
    paddingLeft: theme.spacing(2),
  },
  activeStatusBox: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  activeText: {
    color: theme.palette.common.white,
  },
  statusCardLabel: {
    borderRadius: theme.shape.borderRadius * 2.5,
    color: theme.palette.primary.main,
  },
  statusPill: {
    padding: theme.spacing(0.1, 1),
    borderRadius: theme.shape.borderRadius * 2.5,
  },
  statusCard: {
    borderRadius: theme.shape.borderRadius,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  activeStatusCard: {},
  greenStatusChip: {
    fontWeight,
    backgroundColor: success.backgroundColor,
    color: success.textColor,
    '&:focus, &:active, &:hover': {
      backgroundColor: `${success.backgroundColor} !important`,
      color: `${success.textColor} !important`,
    },
  },
  yellowStatusChip: {
    fontWeight,
    backgroundColor: warn.backgroundColor,
    color: warn.textColor,
    '&:focus, &:active, &:hover': {
      backgroundColor: `${warn.backgroundColor} !important`,
      color: `${warn.textColor} !important`,
    },
  },
  redStatusChip: {
    fontWeight,
    backgroundColor: danger.backgroundColor,
    color: danger.textColor,
    '&:focus, &:active, &:hover': {
      backgroundColor: `${danger.backgroundColor} !important`,
      color: `${danger.textColor} !important`,
    },
  },
  purpleStatusChip: {
    fontWeight,
    backgroundColor: purple.backgroundColor,
    color: purple.textColor,
    '&:focus, &:active, &:hover': {
      backgroundColor: `${purple.backgroundColor} !important`,
      color: `${purple.textColor} !important`,
    },
  },
  orangeStatusChip: {
    fontWeight,
    backgroundColor: orange.backgroundColor,
    color: orange.textColor,
    '&:focus, &:active, &:hover': {
      backgroundColor: `${orange.backgroundColor} !important`,
      color: `${orange.textColor} !important`,
    },
  },
  blueStatusChip: {
    fontWeight,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    '&:focus, &:active, &:hover': {
      backgroundColor: `${theme.palette.primary.main} !important`,
      color: `${theme.palette.common.white} !important`,
    },
  },
  pinkStatusChip: {
    fontWeight,
    backgroundColor: pink.backgroundColor,
    color: pink.textColor,
    '&:focus, &:active, &:hover': {
      backgroundColor: `${pink.backgroundColor} !important`,
      color: `${pink.textColor} !important`,
    },
  },
  whiteStatusChip: {
    fontWeight,
    backgroundColor: theme.palette.common.white,
    borderColor: theme.palette.text.secondary,
    color: theme.palette.text.secondary,
    '&:focus, &:active, &:hover': {
      backgroundColor: `${theme.palette.common.white} !important`,
      color: `${theme.palette.text.secondary} !important`,
      borderColor: theme.palette.text.secondary,
    },
  },
}));
