import React, { useState } from 'react';
import {
  AppBar,
  useMediaQuery,
  Toolbar,
  useTheme,
  styled,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import useMousetrap from 'react-hook-mousetrap';
import SearchResults from '../../../SearchResults';
import SmallScreenItems from './SmallScreenItems';
import LargeScreenItems from './LargeScreenItems';
import { useLocation } from 'react-router-dom';

const primaryAppbarHeight = 60;

const PrimaryAppBar = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [searchOpen, setSearchOpen] = useState(false);
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down('md'));

  useMousetrap(['command+k'], () => {
    setSearchOpen(true);
  });
  useMousetrap(['esc'], () => {
    setSearchOpen(false);
  });

  const logoLength = 30;

  if (pathname.match(/^\/onboarding/)) {
    return null;
  }

  return (
    <>
      <Toolbar
        sx={{
          height: primaryAppbarHeight,
          minHeight: primaryAppbarHeight,
          boxShadow: 'none',
        }}
        color="primary"
      >
        {!smallScreen && (
          <LargeScreenItems
            setSearchOpen={setSearchOpen}
            logoLength={logoLength}
          />
        )}
        {smallScreen && <SmallScreenItems setSearchOpen={setSearchOpen} />}
      </Toolbar>
      <SearchResults open={searchOpen} onClose={() => setSearchOpen(false)} />
    </>
  );
};

export default PrimaryAppBar;
