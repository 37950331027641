import React, { useMemo } from 'react';
import moment from 'moment';
import { InboxEvent } from '@pv/common/graphql/types';
import { useNavigate } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import { InboxItemStatus } from './InboxItemStatus';
import { InboxItemAvatar } from './InboxItemAvatar';
import { htmlToText } from '@pv/common/utils';

export const InboxItemMinimal = ({ event }: { event: InboxEvent }) => {
  const navigate = useNavigate();

  const startDate = useMemo(
    () => (event.startDate ? moment(event.startDate).format('ddd, MMM D') : ''),
    [event.startDate]
  );

  const cleanMessage = useMemo(() => {
    const latestMessage = (event.latestMessageBatched || [])[0];
    return htmlToText(latestMessage?.sanitizedTrimmedHtml || '');
  }, [event.latestMessageBatched]);

  const lastEmailed = useMemo(() => {
    if (event && event.lastEmailedAt) {
      const now = moment();
      const messageDate = moment(event.lastEmailedAt);
      let format;

      format = messageDate.isSame(now, 'day') ? 'h:mm A' : 'MMM D';

      return messageDate.format(format);
    }
  }, [event]);

  return (
    <>
      <InboxItemStatus height={'90px'} status={event.status} />
      <Box
        onClick={() => navigate(`/events/${event.id}`)}
        sx={{
          display: 'flex',
          gap: '20px',
          alignItems: 'top',
          width: '100%',
          position: 'relative',
        }}
      >
        <Box sx={{ flexGrow: 0, flexShrink: 0 }}>
          {event.planner && (
            <InboxItemAvatar
              unreadMessageCount={event.unreadMessageCount}
              person={event.planner}
            />
          )}
        </Box>

        <Box
          sx={{
            flexBasis: 'auto',
            flexGrow: 1,
            flexShrink: 1,
          }}
        >
          <Box sx={{ marginRight: '25%' }}>
            <Typography variant="subtitle1">{event.name}</Typography>
          </Box>

          <Box
            sx={{
              WebkitLineClamp: 1,
              WebkitBoxOrient: 'vertical',
              display: '-webkit-box',
              overflow: 'hidden',
            }}
          >
            <Typography variant="subtitle2">
              {startDate} {'\u2022'} {event.planner?.fullName}
            </Typography>
          </Box>

          <Box
            sx={{
              WebkitLineClamp: 2,
              WebkitBoxOrient: 'vertical',
              display: '-webkit-box',
              overflow: 'hidden',
            }}
          >
            <Typography variant="body1" sx={{ hyphens: 'auto' }}>
              {cleanMessage}
            </Typography>
          </Box>
        </Box>

        <Box sx={{ position: 'absolute', top: 0, right: 0 }}>
          <Typography variant="body2" color="text.secondary">
            {lastEmailed}
          </Typography>
        </Box>
      </Box>
    </>
  );
};
