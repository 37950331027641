import { Divider, Link, Typography, Box } from '@mui/material';
import React from 'react';

import { DevisePage } from '../../components/DevisePage';

export default function PasswordInstructionsPage() {
  return (
    <DevisePage title="Reset Password" maxPaperWidth={390}>
      <Box
        style={{
          display: 'flex',
          flexDirection: 'column',
          padding: 20,
        }}
      >
        <Box
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: 10,
          }}
        >
          <Box>
            <img
              src="/envelope.png"
              alt="envelope"
              style={{
                width: 80,
                height: 'auto',
              }}
            />
          </Box>
        </Box>
        <Box>
          <Box>
            <Typography variant="h5" align="center">
              Check your inbox!
            </Typography>
          </Box>
          <Box
            style={{
              marginTop: 8,
            }}
          >
            <Typography variant="body1" align="center">
              You will receive an email shortly.
            </Typography>
          </Box>
        </Box>
        <Box style={{ marginTop: 40, marginBottom: 40 }}>
          <Divider />
        </Box>
        <Box>
          <Box>
            <Typography
              variant="subtitle1"
              align="center"
              style={{
                fontSize: 16,
              }}
            >
              Didn't receive the email? 🧐
            </Typography>
          </Box>
          <Box
            style={{
              maxWidth: 281,
            }}
          >
            <Typography variant="body2" align="center" color="textSecondary">
              If you don't receive an email, check your spam or junk folder. For
              help contact{' '}
              <Link href="mailto:support@perfectvenue.com">
                <Typography variant="body2" component="span" color="secondary">
                  support@perfectvenue.com
                </Typography>
              </Link>
              .
            </Typography>
          </Box>
        </Box>
        <Box
          style={{
            marginTop: 40,
          }}
        >
          <Typography
            align="center"
            variant="subtitle2"
            style={{
              fontSize: 16,
            }}
          >
            Already know your password?{' '}
            <Link style={{ cursor: 'pointer' }} href="/users/sign_in">
              <Typography
                component="span"
                color="secondary"
                variant="subtitle2"
                style={{
                  fontSize: 16,
                }}
              >
                Sign In
              </Typography>
            </Link>
          </Typography>
        </Box>
      </Box>
    </DevisePage>
  );
}
