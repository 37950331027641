import React from 'react';
import { Grid } from '@material-ui/core';
import { utmTrackingDefaultSourceVals } from '@pv/common/utils';
import { Fields } from '@pv/common/components';
import { useEvent } from '../providers/event';
import { AutoSave } from './AutoSave';

const UtmTracking = ({ onSave, readOnly = false }) => {
  const sourceVals = utmTrackingDefaultSourceVals;
  const sourceValsWithDefault = [['None', 'None', false], ...sourceVals];
  const { event } = useEvent();
  const canUpdateEvent = event?.canUpdate?.value;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <AutoSave
          value={event.utmSource || 'None'}
          onSave={(value) => {
            onSave({ utmSource: value === 'None' ? '' : value });
          }}
          renderField={(value, onChange) => (
            <Fields.PvDropDown
              name="utm-source"
              value={value}
              onChange={(e) => onChange(e.target.value)}
              variant="outlined"
              label="Source"
              vals={sourceValsWithDefault}
              disabled={readOnly || !canUpdateEvent}
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <AutoSave
          value={event.utmCampaign || ''}
          onSave={(value) => {
            onSave({ utmCampaign: value }, { utmCampaign: value });
          }}
          renderField={(value, onChange) => (
            <Fields.PvTextField
              name="utm-campaign"
              value={value}
              onChange={(e) => onChange(e.target.value)}
              variant="outlined"
              label="Campaign"
              disabled={readOnly || !canUpdateEvent}
            />
          )}
        />
      </Grid>
    </Grid>
  );
};

export default UtmTracking;
