import React from 'react';
import sortBy from 'lodash/sortBy';
import { Fields } from '@pv/common/components';
import { startCase } from 'lodash';

export const PaymentOptionsSelect = ({
  paymentSources,
  selectedSourceId,
  onSelect,
  disabled = false,
}) => {
  const paymentOptions = sortBy(paymentSources, ['brand']);

  const selectPaymentOptions = paymentOptions.map((pm) => [
    pm.id,
    `${startCase(pm.brand || pm.bank_name)} x${pm.last4}`,
    false,
  ]);

  return (
    <Fields.PvDropDown
      name="payment-method"
      label="Payment Method"
      value={selectedSourceId || ''}
      onChange={(e) => onSelect(e.target.value)}
      vals={selectPaymentOptions}
      disabled={disabled}
      data-cy="payment-method-select"
    />
  );
};
