import { Badge } from '@mui/material';
import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
  primaryAppBarBadge: {
    fontWeight: theme.typography.subtitle1.fontWeight,
    marginTop: theme.spacing(0.8),
  },
}));

const PrimaryAppBarBadge = ({ children, label }) => {
  const classes = useStyles();

  return (
    <Badge
      color="error"
      classes={{ badge: classes.primaryAppBarBadge }}
      badgeContent={label || 0}
    >
      {children}
    </Badge>
  );
};

export default PrimaryAppBarBadge;
