import React from 'react';
import { Chip } from '@mui/material';
import { CheckCircle } from 'react-feather';
import { PVBlack } from '@pv/common/colors';

export const AvailabilityCheckedChip = () => (
  <Chip
    label="Availability Check"
    icon={<CheckCircle color={'#009688'} width={'20px'} height={'20px'} />}
    sx={{
      color: PVBlack,
      fontWeight: 'medium',
      fontSize: '13px',
      padding: '8px',
      background: 'rgba(177, 243, 217, 0.50)',
    }}
  />
);
