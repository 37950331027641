import React, { useMemo } from 'react';
import { Box } from '@mui/material';
import { InboxEvent } from '@pv/common/graphql/types';

export const InboxRow = ({
  index,
  children,
  event,
}: {
  children: React.ReactNode;
  event: InboxEvent;
  index: number;
}) => {
  const unread = useMemo(
    () => event.unreadMessageCount > 0,
    [event.unreadMessageCount]
  );
  return (
    <Box
      sx={{
        padding: '14px 20px 14px 14px',
        bgcolor: unread ? 'white' : 'rgba(244, 243, 255, 0.5)',
        boxShadow: '0px -1px 0px rgba(0, 0, 0, 0.08)',
        cursor: 'pointer',
        width: '100%',
        '&:hover': {
          bgcolor: unread
            ? 'rgba(244, 243, 255, 0.5)'
            : 'rgba(244, 243, 255, 0.25)',
        },
        display: 'flex',
        gap: '20px',
        alignItems: 'center',
      }}
      data-cy={`inbox-row-${index}`}
    >
      {children}
    </Box>
  );
};
