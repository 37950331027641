import React, { useRef, useState } from 'react';
import { useQuery } from '@apollo/client';
import {
  ClickAwayListener,
  Divider,
  List,
  ListItem,
  ListItemText,
  Paper,
  Popper,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import first from 'lodash/first';
import map from 'lodash/map';

import { plannerSearchQuery } from '@pv/common/graphql';
import { PlannerSearchQuery } from '@pv/common/graphql/operations';
import { phonePrint } from '@pv/common/utils';
import { useVenues } from '../../../../providers/venues';
import ContactsDropdownNewContactButton from '../../../../components/ContactsDropdownNewContactButton';

const useStyles = makeStyles((theme) => ({
  actions: {
    justifyContent: 'flex-end',
  },
  content: {
    overflowY: 'auto',
    maxHeight: '80vh',
  },
  editor: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    outline: 'none',
    boxShadow: theme.shadows[20],
    width: '60%',
    maxWidth: '100%',
  },
  popper: {
    zIndex: theme.zIndex.modal + 100,
  },
  popperContent: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
}));

export const SearchContact = ({
  query,
  onClickSearchResult,
  eventId,
  disableSearch,
  children,
}: {
  query: string;
  onClickSearchResult: (plannerId: string) => void;
  eventId?: string;
  disableSearch: boolean;
  children: React.ReactNode;
}) => {
  const { venues } = useVenues();
  const classes = useStyles();
  const [isSearchPopoverOpen, setIsSearchPopoverOpen] = useState(true);

  const searchRef = useRef<HTMLDivElement>(null);

  const organizationId = first(venues)?.organization?.id;
  const variables = { organizationId, query, eventId, pageSize: 4 };
  const skip = !organizationId || query === '';
  const { data: searchData, loading } = useQuery<PlannerSearchQuery>(
    plannerSearchQuery,
    { variables, fetchPolicy: 'no-cache', skip }
  );
  const plannerSearch = searchData?.plannerSearch;

  const SearchResults = () => (
    <Popper
      placement="bottom-start"
      anchorEl={searchRef.current}
      className={classes.popper}
      open={isSearchPopoverOpen && !!query && !disableSearch}
      transition
    >
      <Paper className={classes.popperContent} elevation={3}>
        <List>
          {map(plannerSearch, (planner) => (
            <ListItem
              key={planner.id}
              button
              onClick={() => onClickSearchResult(planner.id)}
            >
              <ListItemText>
                <div>{planner.fullName}</div>
                <Typography color="textSecondary">
                  {planner.phone && phonePrint(planner.phone)}{' '}
                  {planner.email && `(${planner.email})`}
                </Typography>
              </ListItemText>
            </ListItem>
          ))}
        </List>
        {!loading && query && (
          <>
            <Divider />
            <ContactsDropdownNewContactButton
              query={query}
              onClick={() => {
                setIsSearchPopoverOpen(false);
              }}
            />
          </>
        )}
      </Paper>
    </Popper>
  );

  return (
    <>
      <ClickAwayListener
        onClickAway={() => {
          setIsSearchPopoverOpen(false);
        }}
      >
        <div ref={searchRef}>{children}</div>
      </ClickAwayListener>

      <SearchResults />
    </>
  );
};
