import { useEffect } from 'react';
import { useUserAuth } from '../providers/auth';

const APP_ID = '5f2d89d58c253106b02a15e2';

export default function useCannyClient(client: Window['Canny']) {
  const { user } = useUserAuth();

  useEffect(() => {
    if (process.env.NODE_ENV !== 'production') return;
    if (!user) return;
    if (!client) return;

    console.log('instantiating canny..');
    client('identify', {
      appID: APP_ID,
      user: {
        email: user.email,
        name: user.fullName,
        id: user.id,
        created: user.createdAt,
      },
    });
  }, [user, client]);

  return null;
}
