import React, { createContext, useContext, useState, useMemo } from 'react';

const EventsContext = createContext({
  events: [],
  recurringEvents: [],
  selectedEvent: {},
  setEvents: () => {},
  setRecurringEvents: () => {},
  setSelectedEvent: () => {},
});

export const useEvents = () => useContext(EventsContext);

export const EventsProvider = ({ children }) => {
  const [events, setEvents] = useState([]);
  const [recurringEvents, setRecurringEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState({});
  const memoizedValue = useMemo(
    () => ({
      events,
      recurringEvents,
      selectedEvent,
      setEvents,
      setRecurringEvents,
      setSelectedEvent,
    }),
    [events, recurringEvents, selectedEvent]
  );
  return (
    <EventsContext.Provider value={memoizedValue}>
      {children}
    </EventsContext.Provider>
  );
};

export default EventsProvider;
