import React, { forwardRef } from 'react';
import { TableVirtuoso } from 'react-virtuoso';
import {
  Table,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  TableContainer,
  TableHead,
  Link,
} from '@mui/material';
import moment from 'moment';
import { noop } from 'lodash';

import { EventStatsContainer, Headline } from './EventStats';

/*
 * Types.
 */

export interface Event {
  id: string;
  name: string;
  startDate: string;
  status: string;
}

interface EventsListProps {
  events?: Event[];
  height?: number;
  loadMore?: () => void;
  overscan?: number;
}

/*
 * Constants.
 */

const TableComponents = {
  Scroller: forwardRef<HTMLDivElement>((props, ref) => (
    <TableContainer
      {...props}
      ref={ref}
      sx={{
        border: '1px solid rgba(0, 0, 0, 0.1)',
        borderRadius: '6px',
        width: '100%',
      }}
    />
  )),
  Table: (props: any) => (
    <Table {...props} style={{ borderCollapse: 'separate' }} size="small" />
  ),
  TableHead: forwardRef<HTMLTableSectionElement>((props, ref) => (
    <TableHead
      {...props}
      ref={ref}
      sx={{
        color: 'rgba(0, 0, 0, 0.54)',
        backgroundColor: 'white',
      }}
    />
  )),
  TableRow: TableRow,
  TableBody: forwardRef<HTMLTableSectionElement>((props, ref) => (
    <TableBody {...props} ref={ref} />
  )),
};

/*
 * Component.
 */

export const EventsList: React.FC<EventsListProps> = ({
  events,
  height = 300,
  loadMore = noop,
  overscan = 0,
}: EventsListProps) => {
  return (
    <EventStatsContainer>
      <Headline text="Events" />
      <TableVirtuoso
        style={{ height: `${height}px` }}
        data={events}
        components={TableComponents}
        endReached={loadMore}
        overscan={overscan}
        fixedHeaderContent={() => (
          <TableRow
            sx={{
              outline: '1px solid rgba(0, 0, 0, 0.01)',
              boxShadow: '0px 1px 0px 0px rgba(0, 0, 0, 0.1)',
            }}
          >
            <TableCell sx={{ padding: '8px 16px', width: '70%' }}>
              <Typography variant="tableHeader">Name</Typography>
            </TableCell>
            <TableCell sx={{ padding: '8px 16px' }}>
              <Typography variant="tableHeader">Date</Typography>
            </TableCell>
          </TableRow>
        )}
        itemContent={(index, e) => (
          <>
            <TableCell sx={{ padding: '8px 16px', width: '30%' }}>
              <Link
                key={e.id}
                href={`/events/${e.id}`}
                style={{
                  textDecoration: 'none',
                }}
              >
                <Typography variant="subtitle1">
                  {e.name || 'No Name'}
                </Typography>
              </Link>
            </TableCell>
            <TableCell sx={{ padding: '8px 16px' }}>
              <Typography variant="body1">
                {e.startDate && moment(e.startDate).format('MMM D, YYYY')}
              </Typography>
            </TableCell>
          </>
        )}
      />
    </EventStatsContainer>
  );
};
