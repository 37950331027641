import { useEffect } from 'react';
import { usePublicConfig } from '@pv/common/providers';

export default () => {
  const { publicConfig } = usePublicConfig();
  const email = publicConfig?.hubspotUserEmail;

  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      if (email) {
        window._hsq = window._hsq || [];
        const { _hsq } = window;
        _hsq.push(['identify', { email }]);
      }
    }
  }, [email]);

  return null;
};
