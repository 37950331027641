import { useState, useEffect } from 'react';

export const useUserAgent = () => {
  const [userAgentInfo, setUserAgentInfo] = useState({
    userAgent: '',
    isIpadApp: false,
    isNativeMobileApp: false,
    isWeb: false,
  });

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const ua = window.navigator.userAgent;
      const isIpadApp =
        ua.includes('Perfect Venue Native') && ua.includes('Device:iPad');
      const isNativeMobileApp =
        ua.includes('Perfect Venue Native') &&
        (ua.includes('Device:iPhone') || ua.includes('Device:Android'));

      setUserAgentInfo({
        userAgent: ua,
        isIpadApp,
        isNativeMobileApp,
        isWeb: !isIpadApp && !isNativeMobileApp,
      });
    }
  }, []);

  return userAgentInfo;
};
